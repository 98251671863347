import {
  Button,
  column,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { Price } from "src/components";
import { getGraphQLBaseUrl } from "src/context";
import {
  InvoiceOverviewPageGroupedDrawInterestFragment,
  InvoiceOverviewPageInvoiceFragment,
} from "src/generated/graphql-types";
import { formatWithYear } from "src/utils/dates";

type InvoiceDrawInterestGroupedTableProps = {
  invoice: InvoiceOverviewPageInvoiceFragment;
  groupedDrawInterests: InvoiceOverviewPageGroupedDrawInterestFragment[];
};

export function InvoiceDrawInterestGroupedTable(props: InvoiceDrawInterestGroupedTableProps) {
  const { invoice, groupedDrawInterests } = props;

  const columns = createColumns(groupedDrawInterests);
  const rows = mapToRows(groupedDrawInterests);

  return (
    <>
      <Button
        download
        label="Download"
        onClick={`${getGraphQLBaseUrl()}/csv?type=cfi&invoiceId=${invoice.id}`}
        variant="tertiary"
      />
      <GridTable
        id="invoiceDrawInterestsTable"
        columns={columns}
        rows={rows}
        rowStyles={rowStyles}
        stickyHeader
        style={{ rowHeight: "flexible" }}
      />
    </>
  );
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

type Row = SimpleHeaderAndData<InvoiceOverviewPageGroupedDrawInterestFragment>;

function createColumns(groupedDrawInterests: InvoiceOverviewPageGroupedDrawInterestFragment[]): GridColumn<Row>[] {
  const lotPartitionIdColumn = column<Row>({
    header: "Lot Partition",
    data: (row) => row.lotPartitionDraw.lotPartition.id,
    w: "100px",
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: "Address",
    data: (row) => row.lotPartitionDraw.lotPartition.lot.address?.street1,
    w: "150px",
    sticky: "left",
  });

  const blueprintProjectColumn = column<Row>({
    header: "Blueprint Project",
    data: (row) => row.lotPartitionDraw.lotPartition.blueprintProjectId,
    w: "100px",
    sticky: "left",
  });

  const drawDateColumn = dateColumn<Row>({
    header: () => "Draw Date",
    w: "100px",
    data: (row) => formatWithYear(row.lotPartitionDraw.creditFacilityDraw.drawDate),
  });

  const drawAmountColumn = column<Row>({
    header: () => "Original Draw Amount",
    w: "100px",
    data: (row) => <Price valueInCents={row.lotPartitionDraw.amountInCents} dropZero />,
  });

  const amountColumn = numericColumn<Row>({
    header: () => "Draw Interest Amount (for this Invoice)",
    data: (row) => <Price valueInCents={row.amountInCents} dropZero />,
    w: "140px",
  });

  return [lotPartitionIdColumn, blueprintProjectColumn, addressColumn, drawDateColumn, drawAmountColumn, amountColumn];
}

function mapToRows(groupedDrawInterests: InvoiceOverviewPageGroupedDrawInterestFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(
    groupedDrawInterests.map((drawInterest) => ({
      id: drawInterest.lotPartitionDraw.id,
      ...drawInterest,
    })),
  );
}
