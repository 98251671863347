import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useState } from "react";
import GoogleButton from "react-google-button";

export function LoginButton() {
  const [disabled, setDisabled] = useState(false);

  return (
    <GoogleButton
      type="light"
      disabled={disabled}
      onClick={async () => {
        setDisabled(true);
        // Setting provider allows us to self-host the login button
        // TODO: verify this eslint ignore
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
      }}
    />
  );
}
