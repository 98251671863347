import { BoundDateField, Button, FormLines, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import {
  SaveCreditFacilityDrawRequestInput,
  useCreateCreditFacilityDrawRequestMutation,
} from "src/generated/graphql-types";
import { createDrawRequestDetailsUrl } from "src/RouteUrls";
import { DateOnly } from "src/utils/dates";

type CreateDrawRequestModalProps = {
  fundId: string;
};

export function CreateDrawRequestModal({ fundId }: CreateDrawRequestModalProps) {
  const formState = useFormState({
    config: formConfig,
    init: {
      preparationDate: new DateOnly(new Date()),
    },
  });
  const { closeModal } = useModal();
  const [saveCreditFacilityDrawRequest] = useCreateCreditFacilityDrawRequestMutation();
  const history = useHistory();

  return (
    <>
      <ModalHeader>Create Credit Facility Draw Request</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundDateField field={formState.preparationDate} label="Date" />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" onClick={closeModal} variant="secondary" />
              <Button
                disabled={!formState.valid}
                label="Create"
                onClick={async () => {
                  if (formState.canSave()) {
                    const response = await saveCreditFacilityDrawRequest({
                      variables: {
                        input: {
                          ...mapToInput(formState.value),
                          fundId,
                        },
                      },
                    });
                    const newDrawRequestId = response.data?.saveCreditFacilityDrawRequest.creditFacilityDrawRequest.id;
                    if (newDrawRequestId) {
                      // close global modal then redirect
                      closeModal();
                      history.push({
                        pathname: createDrawRequestDetailsUrl(newDrawRequestId),
                      });
                    }
                  }
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormInput = {
  preparationDate: Date | null | undefined;
};

const formConfig: ObjectConfig<FormInput> = {
  preparationDate: { type: "value", rules: [required] },
};

function mapToInput(form: FormInput): SaveCreditFacilityDrawRequestInput {
  const { preparationDate, ...others } = form;
  return {
    preparationDate: preparationDate && new DateOnly(preparationDate),
    ...others,
  };
}
