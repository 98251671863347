import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { FundOverviewPageFundFragment } from "src/generated/graphql-types";
import { EquityFinancingTable } from "./EquityFinancingTables";
import { FundDetails } from "./FundDetails";
import { FundDrawsTable } from "./FundDrawsTable";
import { FundInvoicesTable } from "./FundInvoicesTable";
import { FundMetrosTable } from "./FundMetrosTable";
import { FundPaymentsTable } from "./FundPaymentsTable";
import { LoanCommitmentsTable } from "./LoanCommitmentsTable";
import { LotsTable } from "./LotsTable";
import { FundDrawRequestsTable } from "./FundDrawRequestsTable";

export type FundOverviewTabsProps = {
  fund: FundOverviewPageFundFragment;
};

export function FundOverviewTabs({ fund }: FundOverviewTabsProps) {
  const [currentTab, setCurrentTab] = useState("Activity Feed");

  const tabs = getTabs({ fund });

  return <TabsWithContent selected={currentTab} onChange={setCurrentTab} tabs={tabs} />;
}

function getTabs(props: FundOverviewTabsProps) {
  const { fund } = props;
  const { id } = fund;

  return [
    {
      name: "Fund Details",
      value: "FundDetails",
      render: () => <FundDetails fundData={fund} />,
    },
    {
      name: "Fund Metros",
      value: "FundMetros",
      render: () => <FundMetrosTable fund={fund} metros={fund.metros} />,
    },
    {
      name: "Investments",
      value: "Investments",
      render: () => <LotsTable lots={fund.lots} fundId={id} />,
    },
    {
      name: "Draw Requests",
      value: "DrawRequests",
      render: () => <FundDrawRequestsTable drawRequests={fund.creditFacilityDrawRequests} fundId={id} />,
    },
    {
      name: "Draws",
      value: "Draws",
      render: () => <FundDrawsTable draws={fund.creditFacilityDraws} fundId={id} />,
    },
    {
      name: "Payments",
      value: "Payments",
      render: () => <FundPaymentsTable payments={fund.creditFacilityPayments} fundId={id} />,
    },
    {
      name: "Invoices",
      value: "Invoices",
      render: () => <FundInvoicesTable invoices={fund.creditFacilityInvoices} fundId={id} />,
    },
    {
      name: "Loan Commitment Summary",
      value: "Loan Commitment Summary",
      render: () => <LoanCommitmentsTable fundId={id} lots={fund.lots} />,
    },
    {
      name: "Equity Usage Summary",
      value: "Equity Usage Summary",
      render: () => <EquityFinancingTable fundId={id} lots={fund.lots} />,
    },
  ];
}
