import {
  Button,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { getGraphQLBaseUrl } from "src/context";
import { FundOverviewPageLotFragment } from "src/generated/graphql-types";
import { createLotDetailsUrl } from "src/RouteUrls";

export type LotsTableProps = {
  lots: FundOverviewPageLotFragment[];
  fundId: string;
};

export function LotsTable(props: LotsTableProps) {
  const { fundId, lots } = props;

  return (
    <>
      <Button
        download
        label="Download Proto-DataTape CSV"
        onClick={`${getGraphQLBaseUrl()}/csv?type=fundDataTape&fundId=${fundId}`}
        variant="tertiary"
      />
      <GridTable
        id="lotsTable"
        rowStyles={rowStyles}
        columns={createLotColumns()}
        rows={createLotRows(lots)}
        fallbackMessage="Investments for this Fund will show here."
      />
    </>
  );
}

type Row = SimpleHeaderAndData<FundOverviewPageLotFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {
    onClick: ({ data }) => {
      // Open the Blueprint contracts list in a new tab
      window.open(`${createLotDetailsUrl(data.id)}`, "_blank", "noopener,noreferrer");
    },
  },
};

function createLotRows(lots: FundOverviewPageLotFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lots);
}

function createLotColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Lot ID",
    data: ({ id }) => id,
  });

  const addressColumn = column<Row>({
    header: () => "Lot Address",
    data: ({ address }) => address?.street1,
  });

  return [idColumn, addressColumn];
}
