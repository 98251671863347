import { LazyQueryResult, OperationVariables, QueryResult } from "@apollo/client";
import { ReactElement } from "react";
import { Loading } from "src/components/Loading";
import { Error } from "src/routes/Error";

type QueryResultOpts<T> = {
  loading?: () => ReactElement;
  error?: (err: Error) => ReactElement;
  data: (data: T) => ReactElement;
  showLoading?: "first" | "always" | "never";
  /** Set if the query pass in is conditionally skipped.
   * If `true`, the loading view will be shown until the query's data is populated.
   * If `false`, it will result in throwing an error (maybe this should never happen?).
   * Defaults to false */
  skippable?: boolean;
};

/** Conditionally renders the loading/error/data boilerplate with default loading/error components. */
export function queryResult<T>(
  result: QueryResult<T> | LazyQueryResult<T, any>,
  fn: (data: T) => ReactElement,
): ReactElement;
export function queryResult<T>(
  result: QueryResult<T> | LazyQueryResult<T, any>,
  opts: QueryResultOpts<T>,
): ReactElement;
export function queryResult<T>(
  result: QueryResult<T> | LazyQueryResult<T, any>,
  fnOrOpts: QueryResultOpts<T> | ((data: T) => ReactElement),
): ReactElement {
  const { loading, error, data, previousData } = result;
  const dataFn = typeof fnOrOpts === "function" ? fnOrOpts : fnOrOpts.data;
  const opts = typeof fnOrOpts === "function" ? { data: dataFn } : fnOrOpts;
  const {
    showLoading = "first",
    loading: loadingFn = () => <Loading />,
    error: errorFn = (err: Error) => <Error error={err} />,
    skippable = false,
  } = opts;
  // Typically we let `data` or `previousData` take precedence over `loading`, so avoid
  // short-term UX flashing, but if you know you've got a long query, setting
  // `showLoading = "always"` will push loading to the front of the list.
  const mustLoad = loading && showLoading === "always";
  if (error) {
    return errorFn(error);
  } else if (data && !mustLoad) {
    return dataFn(data);
  } else if (previousData && !mustLoad) {
    return dataFn(previousData);
  } else if (loading) {
    if (showLoading === "never") {
      return <></>;
    } else {
      return loadingFn();
    }
  } else {
    return skippable ? loadingFn() : errorFn(new global.Error("No data"));
  }
}

/**
 * Conditionally renders the loading/error/data boilerplate with default loading/error components.
 *
 * However, this version works with the `cache-and-network` fetch policy, by prioritizing the availability
 * of data over whether or not the query is `loading`.
 *
 * We may be able to replace `queryResult` with this implementation, but wanted to limit the scope of change for now.
 */
export function queryResultDataFirst<T>(
  result: QueryResult<T> | LazyQueryResult<T, OperationVariables>,
  opts: QueryResultOpts<T>,
): ReactElement {
  const { loading, error, data } = result;
  if (data) {
    return opts.data(data);
  } else if (loading) {
    return opts.loading ? opts.loading() : <Loading />;
  } else if (error) {
    return <Error error={error} />;
  } else {
    const error = new global.Error("No data");
    return <Error error={error} />;
  }
}

/** Conditionally renders the loading/error/data for multiple queries. */
export function queriesResult<T extends ReadonlyArray<QueryResult<any>>>(
  queries: T,
  opts: {
    loading?: () => ReactElement;
    error?: (err: Error) => ReactElement;
    data: (
      ...data: {
        [Q in keyof T]: T[Q] extends { data: infer U | undefined } ? U : never;
      }
    ) => ReactElement;
  },
): ReactElement {
  if (queries.some((q) => q.loading)) {
    return opts.loading ? opts.loading() : <Loading />;
  } else if (queries.some((q) => q.error !== undefined)) {
    return <Error error={queries.find((q) => q.error !== undefined)!.error!} />;
  } else {
    return opts.data(...(queries.map((q) => q.data) as any));
  }
}

/** Provides a type-guard for checking non-loading/non-error. */
export function hasData<T>(result: QueryResult<T>): result is Omit<QueryResult<T>, "data"> & { data: T } {
  const { error, data } = result;
  // If we have fetchPolicy:'cache-and-network' set on the query, the data may be present but the cache still empty
  return !error && !!data && Object.keys(data).length > 0;
}

export function renderLoadingOrError(result: QueryResult<any>): ReactElement {
  const { error, loading } = result;
  if (loading) {
    return <Loading />;
  } else if (error) {
    return <Error error={error} />;
  } else {
    throw new global.Error(`Expected ${loading} or ${error} to be truthy`);
  }
}
