import { BoundNumberField, Css, IconButton, Palette } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { Price } from "src/components";
import {
  PaymentOverviewPagePaymentFragment,
  SaveCreditFacilityPaymentInput,
  useDeletePaymentMutation,
  usePaymentOverviewPaymentQuery,
  useSaveCreditFacilityPaymentMutation,
} from "src/generated/graphql-types";
import { createFundDetailsUrl } from "src/RouteUrls";
import { formatWithYear } from "src/utils/dates";

type PaymentDetailsProps = {
  payment: PaymentOverviewPagePaymentFragment;
};

export type SavePaymentDetails = SaveCreditFacilityPaymentInput;

export function PaymentDetails({ payment }: PaymentDetailsProps) {
  const query = usePaymentOverviewPaymentQuery({
    variables: { paymentId: payment.id },
  });
  const [savePaymentMutation] = useSaveCreditFacilityPaymentMutation();
  const [deletePaymentMutation] = useDeletePaymentMutation();
  const history = useHistory();

  const formConfig: ObjectConfig<SavePaymentDetails> = useMemo(
    () => ({
      id: { type: "value" },
      amountInCents: { type: "value", rules: [required] },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      query,
      map: ({ creditFacilityPayments: [payment] }) => ({
        ...payment,
      }),
    },
    autoSave: savePayment,
  });

  async function savePayment() {
    await savePaymentMutation({
      variables: { input: formState.value },
    });
  }

  const deleteDisabled = !payment.canDelete.allowed;
  const deleteDisabledReasons = payment.canDelete.disabledReasons.map((reason) => reason.message).join("; ");
  return (
    <div css={Css.df.aic.$}>
      <div css={Css.df.wPx(400).fs0.fdc.p2.bgGray100.$}>
        <div css={Css.df.fdc.gap3.$}>
          <BoundNumberField
            borderless
            label="Total Payment Amount"
            field={formState.amountInCents}
            placeholder="Amount"
          />
        </div>
        <span>Amount Allocated to Paying off Draws</span>
        <Price valueInCents={payment.allocatedAmountInCents} />
        <span>Payment Date</span>
        <span>{formatWithYear(payment.paymentDate)}</span>
        <IconButton
          icon="trash"
          data-testid={`delete`}
          onClick={async () => {
            // Delete this payment
            await deletePaymentMutation({
              variables: { input: { id: payment.id } },
            });
            // And redirect back to the parent page
            history.push({ pathname: createFundDetailsUrl(payment.fund.id) });
          }}
          color={deleteDisabled ? Palette.Gray400 : Palette.Red400}
          disabled={deleteDisabled}
          tooltip={deleteDisabled ? deleteDisabledReasons : "Delete this Payment"}
        />
      </div>
    </div>
  );
}
