import { Css, Properties } from "@homebound/beam";
import { HasChildren } from "src/utils/types";

export function PageHeading(props: HasChildren & { xss?: Properties }) {
  const { xss = {} } = props;
  return (
    <h1
      css={{
        ...Css.my2.xl2Sb.gray800.df.aic.gap1.$,
        ...xss,
      }}
      data-testid={props["data-testid"] || "PageHeading"}
    >
      {props.children}
    </h1>
  );
}

export function PageHeadingDetails(props: HasChildren) {
  return <span css={Css.gray700.sm.$}>{props.children}</span>;
}

export function SubHeading(props: HasChildren) {
  // Using padding here instead of margin in order to not allow content that might be scrolling beneath it to bleed through.
  return (
    <div css={Css.sm.gray700.py1.$} data-testid={props["data-testid"] || "SubHeading"}>
      {props.children}
    </div>
  );
}

export function SectionHeading(props: HasChildren) {
  return (
    <div css={Css.xl2Sb.gray800.$} data-testid={props["data-testid"] || "SectionHeading"}>
      {props.children}
    </div>
  );
}

export function CaptionText(props: HasChildren) {
  return (
    <div css={Css.tinySb.gray700.$} data-testid={props["data-testid"] || "CaptionText"}>
      {props.children}
    </div>
  );
}
