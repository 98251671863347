import {
  Button,
  column,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
} from "@homebound/beam";
import { Percentage, Price } from "src/components";
import { FundOverviewPageDrawFragment } from "src/generated/graphql-types";
import { createDrawDetailsUrl } from "src/RouteUrls";
import { formatWithYear } from "src/utils/dates";
import { CreateDrawModal } from "../createDrawModal/CreateDrawModal";
import { BillExportModal } from "../billExportModal/BillExportModal";

export type FundDrawsTableProps = {
  draws: FundOverviewPageDrawFragment[];
  fundId: string;
};

export function FundDrawsTable(props: FundDrawsTableProps) {
  const { draws, fundId } = props;

  const { openModal } = useModal();

  return (
    <>
      <GridTable
        id="drawsTable"
        rowStyles={rowStyles}
        columns={createDrawColumns()}
        rows={createDrawRows(draws)}
        fallbackMessage="Draws for this Fund will show here."
      />
      <Button
        label="Create Draw"
        variant="secondary"
        onClick={() =>
          openModal({
            content: <CreateDrawModal fundId={fundId} drawRequestId={undefined} />,
          })
        }
      />
      <Button
        label="Create Bill Export For Draw"
        variant="secondary"
        onClick={() =>
          openModal({
            content: <BillExportModal fundId={fundId} endDate={undefined} minimumBillAmountInCents={undefined} />,
          })
        }
      />
    </>
  );
}

type Row = SimpleHeaderAndData<FundOverviewPageDrawFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {
    onClick: ({ data }) => {
      window.open(`${createDrawDetailsUrl(data.id)}`, "_blank", "noopener,noreferrer");
    },
  },
};

function createDrawRows(draws: FundOverviewPageDrawFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(draws);
}

function createDrawColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Credit Facility Draw ID",
    data: ({ id }) => id,
  });

  const drawDateColumn = dateColumn<Row>({
    header: () => "Draw Date",
    data: ({ drawDate }) => formatWithYear(drawDate),
  });

  const amountColumn = column<Row>({
    header: () => "Amount Drawn",
    data: ({ amountInCents }) => getPriceComponent(amountInCents),
  });

  const rateColumn = column<Row>({
    header: () => "Interest Rate",
    data: ({ fixedRateBasisPoints }) => getPercentComponent(fixedRateBasisPoints),
  });

  return [idColumn, drawDateColumn, amountColumn, rateColumn];
}

function getPriceComponent(value: number | undefined) {
  return <Price valueInCents={value} dropZero />;
}

function getPercentComponent(value: number | undefined) {
  const percent = value ? value / 100 : 0;
  return <Percentage percent={percent} dropZero />;
}
