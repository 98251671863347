// organize-imports-ignore
import "src/globalThis";
import { ApolloProvider } from "@apollo/client";
import { CssReset, defaultStyle, setGridTableDefaults } from "@homebound/beam";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { App } from "./App";
import { amplifyConfig, createApolloClient, getStage } from "./context";
// import { configure } from "mobx";
import "src/utils/fetchMonkeyPatch";
import { configureAuth } from "@homebound/auth-components";

const stage = getStage();
const apolloClient = createApolloClient(stage);
configureAuth(amplifyConfig(stage));

// configure({ enforceActions: "never" });
// Amplify.Logger.LOG_LEVEL = "DEBUG";

// Allow developers to easily turn on the conditional id columns for debugging
// Object.assign(global, { showIdColumn, hideIdColumn });

// Apply grid table defaults for empty cells
const emptyCellDash = "-";
setGridTableDefaults({ style: { ...defaultStyle, emptyCell: emptyCellDash } });

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <CssReset />
        <App />
      </QueryParamProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById("root"),
);
