import { BoundNumberField, Css, IconButton, Palette } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { Price } from "src/components";
import {
  InvoiceOverviewPageInvoiceFragment,
  SaveCreditFacilityInvoiceInput,
  useDeleteInvoiceMutation,
  useInvoiceOverviewInvoiceQuery,
  useSaveCreditFacilityInvoiceMutation,
} from "src/generated/graphql-types";
import { createFundDetailsUrl } from "src/RouteUrls";
import { formatWithYear } from "src/utils/dates";

type InvoiceDetailsProps = {
  invoice: InvoiceOverviewPageInvoiceFragment;
};

export type SaveInvoiceDetails = SaveCreditFacilityInvoiceInput;

export function InvoiceDetails({ invoice }: InvoiceDetailsProps) {
  const query = useInvoiceOverviewInvoiceQuery({
    variables: { invoiceId: invoice.id },
  });
  const [saveInvoiceMutation] = useSaveCreditFacilityInvoiceMutation();
  const [deleteInvoiceMutation] = useDeleteInvoiceMutation();
  const history = useHistory();

  const formConfig: ObjectConfig<SaveInvoiceDetails> = useMemo(
    () => ({
      id: { type: "value" },
      amountInCents: { type: "value", rules: [required] },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      query,
      map: ({ creditFacilityInvoices: [invoice] }) => ({
        ...invoice,
      }),
    },
    autoSave: saveInvoice,
  });

  async function saveInvoice() {
    await saveInvoiceMutation({
      variables: { input: formState.value },
    });
  }

  const deleteDisabled = !invoice.canDelete.allowed;
  const deleteDisabledReasons = invoice.canDelete.disabledReasons.map((reason) => reason.message).join("; ");
  return (
    <div css={Css.df.aic.$}>
      <div css={Css.df.wPx(400).fs0.fdc.p2.bgGray100.$}>
        <div css={Css.df.fdc.gap3.$}>
          <BoundNumberField
            borderless
            label="Total Invoice Amount"
            field={formState.amountInCents}
            placeholder="Amount"
          />
        </div>
        <span>Amount From Draw Interest</span>
        <Price valueInCents={invoice.allocatedAmountInCents} />
        <span>Invoice Date</span>
        <span>{formatWithYear(invoice.invoiceDate)}</span>
        <IconButton
          icon="trash"
          data-testid={`delete`}
          onClick={async () => {
            // Delete this invoice
            await deleteInvoiceMutation({
              variables: { input: { id: invoice.id } },
            });
            // And redirect back to the parent page
            history.push({ pathname: createFundDetailsUrl(invoice.fund.id) });
          }}
          color={deleteDisabled ? Palette.Gray400 : Palette.Red400}
          disabled={deleteDisabled}
          tooltip={deleteDisabled ? deleteDisabledReasons : "Delete this Invoice"}
        />
      </div>
    </div>
  );
}
