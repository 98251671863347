export const lotsPath = "/lots";
export const lotPath = "/lotDetails/:lotId(l:\\d*)";

export const fundsPath = "/funds";
export const fundPath = "/fundDetails/:fundId(fund:\\d*)";

export const drawPath = "/drawDetails/:drawId(cfd:\\d*)";
export const drawRequestPath = "/drawRequestDetails/:drawRequestId(cfdr:\\d*)";

export const paymentPath = "/paymentDetails/:paymentId(cfp:\\d*)";

export const invoicePath = "/invoiceDetails/:invoiceId(cfi:\\d*)";

export const metrosPath = "/metros";
