import {
  BoundNumberField,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  ScrollableContent,
  SimpleHeaderAndData,
  column,
  simpleDataRows,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import {
  Maybe,
  MetrosPageMetroFragment,
  SaveMetroInput,
  useMetrosPageMetrosQuery,
  useSaveMetroMutation,
} from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { PageHeader } from "../layout/PageHeader";
import { queryResult } from "src/utils/queryResult";

export function MetrosPage() {
  useDocumentTitle("Metros");

  const query = useMetrosPageMetrosQuery({ variables: {} });

  return queryResult(query, {
    data: (data) => {
      const { metros } = data;
      const title = `Metros`;
      return (
        <>
          <PageHeader title={title} />
          <ScrollableContent>
            <div css={Css.df.py2.aifs.jcsb.$} data-testid="metrosPage">
              <div css={Css.fg1.$}>
                <MetrosTable metros={metros} />
              </div>
            </div>
          </ScrollableContent>
        </>
      );
    },
  });
}

type MetrosTableProps = {
  metros: MetrosPageMetroFragment[];
};

export function MetrosTable(props: MetrosTableProps) {
  const { metros } = props;
  const [saveMetroMutation] = useSaveMetroMutation();

  const formConfigMetro: ObjectConfig<FormInput> = useMemo(
    () => ({
      metros: {
        type: "list",
        config: metroConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfigMetro,
    init: {
      input: metros,
      map: (metros) => ({ metros }),
    },
    autoSave: saveMetros,
  });

  async function saveMetros(formState: ObjectState<FormInput>) {
    const changedMetros =
      formState.changedValue.metros?.filter(
        (m) => m.listToCloseRatioBasisPoints !== undefined || m.deliveryToSalesReadyInDays !== undefined,
      ) || [];
    await Promise.all(
      changedMetros.map(async (metro) =>
        saveMetroMutation({
          variables: { input: { ...metro } },
        }),
      ),
    );
  }

  return (
    <>
      <GridTable
        id="metrosTable"
        rowStyles={rowStyles}
        columns={createMetroColumns(metros, formState)}
        rows={createMetroRows(metros)}
        fallbackMessage="Metros that have been configured will show here."
      />
    </>
  );
}

type Row = SimpleHeaderAndData<MetrosPageMetroFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createMetroRows(metros: MetrosPageMetroFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(metros);
}

function createMetroColumns(metros: MetrosPageMetroFragment[], formState: ObjectState<FormInput>): GridColumn<Row>[] {
  const metroIdColumn = column<Row>({
    header: () => "Id",
    data: ({ id }) => id,
  });

  const metroNameColumn = column<Row>({
    header: () => "Metro",
    data: ({ name }) => name,
  });

  const metroCodeColumn = column<Row>({
    header: () => "Code",
    data: ({ code }) => code,
  });

  const listToCloseRatio = column<Row>({
    header: () => "List to Close Ratio",
    data: (row) => {
      const formStateField = formState.metros.rows.find((metro) => metro.id.value === row.id);
      return {
        value: () => row.listToCloseRatioBasisPoints,
        content: () => <BoundNumberField type="basisPoints" field={formStateField!.listToCloseRatioBasisPoints} />,
      };
    },
  });

  const deliveryToSalesReadyColumn = column<Row>({
    header: () => "Delivery to Sales Ready Days",
    data: (row) => {
      const formStateField = formState.metros.rows.find((metro) => metro.id.value === row.id);
      return {
        value: () => row.deliveryToSalesReadyInDays,
        content: () => <BoundNumberField type="days" field={formStateField!.deliveryToSalesReadyInDays} />,
      };
    },
  });

  return [metroIdColumn, metroNameColumn, metroCodeColumn, listToCloseRatio, deliveryToSalesReadyColumn];
}

export type SaveMetroDetails = SaveMetroInput;

type FormInput = SaveMetroDetails & {
  metros: Maybe<SaveMetroDetails[]>;
};

export const metroConfig: ObjectConfig<SaveMetroDetails> = {
  id: { type: "value" },
  listToCloseRatioBasisPoints: { type: "value", rules: [required] },
  deliveryToSalesReadyInDays: { type: "value", rules: [required] },
};
