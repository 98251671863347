import { BoundCheckboxField, BoundNumberField, BoundTextField, Css } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import {
  FundOverviewPageFundFragment,
  SaveFundInput,
  useFundOverviewFundQuery,
  useSaveFundMutation,
} from "src/generated/graphql-types";

type FundDetailsProps = {
  fundData: FundOverviewPageFundFragment;
};

export type SaveFundDetails = SaveFundInput;

export function FundDetails({ fundData }: FundDetailsProps) {
  const query = useFundOverviewFundQuery({
    variables: { fundId: fundData.id },
  });
  const [saveFundMutation] = useSaveFundMutation();

  const formConfig: ObjectConfig<SaveFundDetails> = useMemo(
    () => ({
      id: { type: "value" },
      name: { type: "value", rules: [required] },
      targetInvestorMarginBasisPoints: { type: "value", rules: [required] },
      opcoDirectHardCostMarkupBasisPoints: { type: "value", rules: [required] },
      opcoIndirectHardCostMarkupBasisPoints: { type: "value", rules: [required] },
      opcoSoftCostMarkupBasisPoints: { type: "value", rules: [required] },
      opcoSiteAcquisitionMarkupBasisPoints: { type: "value", rules: [required] },
      creditFacilityFixedRateBasisPoints: { type: "value", rules: [required] },
      creditFacilityCanBorrowDirectCosts: { type: "value", rules: [required] },
      creditFacilityCanBorrowIndirectCosts: { type: "value", rules: [required] },
      creditFacilityCanBorrowSoftCosts: { type: "value", rules: [required] },
      equityFinancingBasisPoints: { type: "value", rules: [required] },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      query,
      map: ({ funds: [fund] }) => ({
        ...fund,
      }),
    },
    autoSave: saveFund,
  });

  async function saveFund() {
    await saveFundMutation({
      variables: { input: formState.value },
    });
  }

  return (
    <div css={Css.df.aic.$}>
      <div css={Css.df.wPx(400).fs0.fdc.p2.bgGray100.$}>
        <div css={Css.df.fdc.gap3.$}>
          <BoundTextField
            borderless
            label="Fund Name"
            field={formState.name}
            placeholder="Add Fund Name"
            xss={Css.xl.$}
          />
          <BoundNumberField
            field={formState.targetInvestorMarginBasisPoints}
            type="basisPoints"
            label="Default Target Investor Margin"
          />
          <BoundNumberField
            field={formState.opcoDirectHardCostMarkupBasisPoints}
            type="basisPoints"
            label="OpCo Direct Hard Cost Markup"
          />
          <BoundNumberField
            field={formState.opcoIndirectHardCostMarkupBasisPoints}
            type="basisPoints"
            label="OpCo Indirect Hard Cost Markup"
          />
          <BoundNumberField
            field={formState.opcoSoftCostMarkupBasisPoints}
            type="basisPoints"
            label="OpCo Soft Cost Markup"
          />
          <BoundNumberField
            field={formState.opcoSiteAcquisitionMarkupBasisPoints}
            type="basisPoints"
            label="OpCo Site Acquisition Cost Markup"
          />
          <BoundNumberField
            field={formState.creditFacilityFixedRateBasisPoints}
            type="basisPoints"
            label="Credit Facility Fixed Rate"
          />
          <BoundNumberField
            field={formState.equityFinancingBasisPoints}
            type="basisPoints"
            label="Equity Financing Fixed Rate"
          />
          <BoundCheckboxField field={formState.creditFacilityCanBorrowDirectCosts} />
          <BoundCheckboxField field={formState.creditFacilityCanBorrowIndirectCosts} />
          <BoundCheckboxField field={formState.creditFacilityCanBorrowSoftCosts} />
        </div>
      </div>
    </div>
  );
}
