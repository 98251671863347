import {
  column,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { ReactNode } from "react";
import { Price } from "src/components";
import { FundOverviewPageLotFragment, FundOverviewPageLotPartitionFragment } from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";

export type LoanCommitmentsTableProps = {
  lots: FundOverviewPageLotFragment[];
  fundId: string;
};

export function LoanCommitmentsTable(props: LoanCommitmentsTableProps) {
  const { lots } = props;
  const lotPartitions = lots.flatMap((lot) => lot.partitions).compact();

  return (
    <>
      <span>Draws taken each Month</span>
      <GridTable
        id="loanCommitmentsTable"
        rowStyles={rowStyles}
        columns={createOriginalDrawColumns(lotPartitions)}
        rows={createLotRows(lotPartitions)}
        fallbackMessage="Draws by date for lot partitions will appear here."
        sorting={{ on: "client" }}
        stickyHeader
      />
      <span>Outstanding Draws each Month</span>
      <GridTable
        id="loanCommitmentsTable"
        rowStyles={rowStyles}
        columns={createOutstandingDrawColumns(lotPartitions)}
        rows={createLotRows(lotPartitions)}
        fallbackMessage="Draws by date for lot partitions will appear here."
        sorting={{ on: "client" }}
        stickyHeader
      />
    </>
  );
}

type Row = SimpleHeaderAndData<FundOverviewPageLotPartitionFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createLotRows(lots: FundOverviewPageLotPartitionFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lots);
}

function createSharedColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Lot Partition ID",
    data: ({ id }) => id,
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: () => "Lot Address",
    data: ({ lot }) => lot.address?.street1,
    sticky: "left",
  });

  const blueprintIdColumn = column<Row>({
    header: () => "Blueprint Id",
    data: ({ blueprintProjectId }) => blueprintProjectId,
    sticky: "left",
  });

  return [idColumn, addressColumn, blueprintIdColumn];
}

function createOriginalDrawColumns(lotPartitions: FundOverviewPageLotPartitionFragment[]): GridColumn<Row>[] {
  const sharedColumns = createSharedColumns();

  const drawDates = lotPartitions
    .flatMap((lp) => {
      return lp.draws.map((draw) => draw.creditFacilityDraw.drawDate);
    })
    .sort()
    .unique();

  const drawDateColumns = drawDates.map((drawDate) => {
    return column<Row>({
      header: () => String(drawDate),
      data: (row) => drawAmount(row, drawDate),
    });
  });

  return [...sharedColumns, ...drawDateColumns];
}

function createOutstandingDrawColumns(lotPartitions: FundOverviewPageLotPartitionFragment[]): GridColumn<Row>[] {
  const sharedColumns = createSharedColumns();

  const drawDates = lotPartitions
    .flatMap((lp) => {
      return lp.draws.map((draw) => draw.creditFacilityDraw.drawDate);
    })
    .sort()
    .unique();

  const drawDateColumns = drawDates.map((drawDate) => {
    return column<Row>({
      header: () => String(drawDate),
      data: (row) => drawOutstandingAmount(row, drawDate),
    });
  });

  return [...sharedColumns, ...drawDateColumns];
}

function drawAmount(row: FundOverviewPageLotPartitionFragment, drawDate: DateOnly): GridCellContent {
  function content(drawAmount: number): ReactNode {
    return <Price valueInCents={drawAmount} dropZero />;
  }
  const draw = row.draws.find((draw) => draw.creditFacilityDraw.drawDate === drawDate);

  return {
    alignment: "left",
    content: content(draw?.amountInCents ?? 0),
    value: draw?.amountInCents ?? 0,
  };
}

function drawOutstandingAmount(row: FundOverviewPageLotPartitionFragment, drawDate: DateOnly): GridCellContent {
  function content(drawAmount: number): ReactNode {
    return <Price valueInCents={drawAmount} dropZero />;
  }
  const draw = row.draws.find((draw) => draw.creditFacilityDraw.drawDate === drawDate);

  return {
    alignment: "left",
    content: content(draw?.remainingAmountInCents ?? 0),
    value: draw?.remainingAmountInCents ?? 0,
  };
}
