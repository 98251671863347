import {
  BoundDateField,
  BoundNumberField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import { SaveCreditFacilityDrawInput, useCreateCreditFacilityDrawMutation } from "src/generated/graphql-types";
import { createDrawDetailsUrl } from "src/RouteUrls";
import { DateOnly } from "src/utils/dates";

type CreateDrawModalProps = {
  fundId: string;
  drawRequestId: string | undefined;
};

export function CreateDrawModal({ fundId, drawRequestId }: CreateDrawModalProps) {
  const formState = useFormState({
    config: formConfig,
    init: {
      fixedRateDisplay: 0,
      drawDate: new DateOnly(new Date()),
    },
  });
  const { closeModal } = useModal();
  const [saveCreditFacilityDraw] = useCreateCreditFacilityDrawMutation();
  const history = useHistory();

  const title = !drawRequestId
    ? "Create Credit Facility Draw"
    : `Create Credit Facility Draw From Request ${drawRequestId}`;
  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundDateField field={formState.drawDate} label="Draw Completed Date" />
          <BoundNumberField
            field={formState.fixedRateDisplay}
            type="percent"
            label="Fixed Rate"
            numFractionDigits={5}
          />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" onClick={closeModal} variant="secondary" />
              <Button
                disabled={!formState.valid}
                label="Create"
                onClick={async () => {
                  if (formState.canSave()) {
                    const response = await saveCreditFacilityDraw({
                      variables: {
                        input: {
                          ...mapToInput(formState.value),
                          fundId,
                          requestId: drawRequestId,
                        },
                      },
                    });
                    const newDrawId = response.data?.saveCreditFacilityDraw.creditFacilityDraw.id;
                    if (newDrawId) {
                      // close global modal then redirect
                      closeModal();
                      history.push({ pathname: createDrawDetailsUrl(newDrawId) });
                    }
                  }
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormInput = {
  drawDate: Date | null | undefined;
  fixedRateDisplay: number | null | undefined;
};

const formConfig: ObjectConfig<FormInput> = {
  drawDate: { type: "value", rules: [required] },
  fixedRateDisplay: { type: "value", rules: [required] },
};

function mapToInput(form: FormInput): SaveCreditFacilityDrawInput {
  const { drawDate, fixedRateDisplay, ...others } = form;
  return {
    drawDate: drawDate && new DateOnly(drawDate),
    fixedRateThousandthsBasisPoints: fixedRateDisplay ? Math.round(fixedRateDisplay * 1000_00) : undefined,
    ...others,
  };
}
