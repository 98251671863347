import { Css } from "@homebound/beam";
import { Maybe } from "src/generated/graphql-types";
import { useTestIds } from "src/hooks/useTestIds";
import { formatNumberToString } from "src/utils";

const emptyCellDash = "-";

type PercentageProps = {
  id?: string;
  percent: Maybe<number>;
  dropZero?: boolean;
};

export function Percentage(props: PercentageProps) {
  const { id = "percentage", percent, dropZero = false } = props;
  const [testId] = useTestIds(id);
  const isNegative = !!percent && percent < 0;
  const formattedPercentage =
    typeof percent !== "number" || (dropZero && percent === 0)
      ? emptyCellDash
      : `${formatNumberToString(percent, true)}%`;
  return (
    <span {...testId} css={Css.if(isNegative).red600.$}>
      {formattedPercentage}
    </span>
  );
}
