import { datadogRum } from "@datadog/browser-rum";
import { AuthViewProps } from "@homebound/auth-components";
import { BeamProvider, Css, PreventBrowserScroll, ScrollableParent } from "@homebound/beam";
import React, { useEffect } from "react";
import { NavigationContextProvider } from "src/components/navigation";
import { PropCoProvider } from "src/contexts/PropCoContext";
import { GlobalNav } from "src/routes/layout/GlobalNav";

export type PageLayoutProps = {
  children?: React.ReactNode;
} & Pick<AuthViewProps, "user" | "setIsAuthenticated">;

/** Wraps the application pages (passed as children) with our logged-in-user layout/nav. */
export function PageLayout({ user, children }: PageLayoutProps) {
  useEffect(() => {
    if (user) {
      datadogRum.setUser({ id: user.email, email: user.email, name: user.name });
    }
  }, [user]);

  return (
    <BeamProvider>
      <NavigationContextProvider>
        <PropCoProvider>
          <PreventBrowserScroll>
            <GlobalNav user={user} />
            <ScrollableParent tagName="main" xss={Css.px3.$}>
              {children}
            </ScrollableParent>
          </PreventBrowserScroll>
        </PropCoProvider>
      </NavigationContextProvider>
    </BeamProvider>
  );
}
