import {
  BoundNumberField,
  Button,
  column,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import {
  Maybe,
  PaymentOverviewPageLotPartitionDrawPaymentFragment,
  PaymentOverviewPagePaymentFragment,
  SaveCreditFacilityDrawInput,
  SaveLotPartitionDrawInput,
  useSaveLotPartitionDrawPaymentMutation,
} from "src/generated/graphql-types";
import { centsToDollars, formatNumberToString } from "src/utils";
import { formatWithYear } from "src/utils/dates";
import { AddLotPartitionDrawsPaymentModal } from "./AddLotPartitionDrawsPaymentModal";

type PaymentLotPartitionDrawPaymentsTableProps = {
  payment: PaymentOverviewPagePaymentFragment;
  lotPartitionDrawPayments: PaymentOverviewPageLotPartitionDrawPaymentFragment[];
};

export type SaveDrawDetails = SaveCreditFacilityDrawInput;

export function PaymentLotPartitionDrawPaymentsTable(props: PaymentLotPartitionDrawPaymentsTableProps) {
  const { payment, lotPartitionDrawPayments } = props;

  const formConfig: ObjectConfig<FormInput> = useMemo(
    () => ({
      id: { type: "value" },
      lotPartitionDrawPayments: {
        type: "list",
        config: lotPartitionDrawConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      input: payment,
      map: mapToForm,
    },
    autoSave: saveLotPartitionDrawPayments,
  });
  const [saveLotPartitionDrawPayment] = useSaveLotPartitionDrawPaymentMutation();

  async function saveLotPartitionDrawPayments(formState: ObjectState<FormInput>) {
    const changedLotPartitionDrawPayments =
      formState.changedValue.lotPartitionDrawPayments?.filter((lpd) => lpd.amountInCents !== undefined) || [];
    await Promise.all(
      changedLotPartitionDrawPayments.map(async (lpd) =>
        saveLotPartitionDrawPayment({
          variables: { input: { ...lpd } },
        }),
      ),
    );
  }

  const columns = createColumns(lotPartitionDrawPayments, formState);
  const rows = mapToRows(lotPartitionDrawPayments);

  const { openModal } = useModal();

  return (
    <>
      <GridTable
        id="drawLotPartitionDrawPaymentsTable"
        columns={columns}
        rows={rows}
        rowStyles={rowStyles}
        sorting={{ on: "client" }}
        stickyHeader
        style={{ rowHeight: "flexible" }}
      />
      <Button
        label="Add Payments For Lot"
        variant="secondary"
        onClick={() =>
          openModal({
            content: <AddLotPartitionDrawsPaymentModal paymentId={payment.id} lots={payment.fund.lots} />,
          })
        }
      />
    </>
  );
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

type Row = SimpleHeaderAndData<PaymentOverviewPageLotPartitionDrawPaymentFragment>;

function createColumns(
  lotPartitionDrawPayments: PaymentOverviewPageLotPartitionDrawPaymentFragment[],
  formState: ObjectState<FormInput>,
): GridColumn<Row>[] {
  const lotPartitionIdColumn = column<Row>({
    header: "Lot Partition Draw",
    data: (row) => row.lotPartitionDraw.id,
    w: "240px",
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: "Address",
    data: (row) => row.lotPartitionDraw.lotPartition.lot.address?.street1,
    w: "240px",
    sticky: "left",
  });

  const blueprintProjectColumn = column<Row>({
    header: "Blueprint Project",
    data: (row) => row.lotPartitionDraw.lotPartition.blueprintProjectId,
    w: "240px",
    sticky: "left",
  });

  const drawAmountColumn = column<Row>({
    header: "Draw Amount",
    data: (row) => formatCentsToPrice(row.lotPartitionDraw.amountInCents),
    w: "240px",
    sticky: "left",
  });

  const remainingDrawAmountColumn = column<Row>({
    header: "Remaining Draw Amount",
    data: (row) => formatCentsToPrice(row.lotPartitionDraw.remainingAmountInCents),
    w: "240px",
    sticky: "left",
  });

  const drawDateColumn = dateColumn<Row>({
    header: "Draw Date",
    data: (row) => formatWithYear(row.lotPartitionDraw.creditFacilityDraw.drawDate),
    w: "240px",
    sticky: "left",
  });

  const amountColumn = numericColumn<Row>({
    header: () => "Draw Payment Amount",
    data: (row) => {
      const formStateField = formState.lotPartitionDrawPayments.rows.find((lpd) => lpd.id.value === row.id);
      return {
        value: () => row.amountInCents,
        content: () => <BoundNumberField type="cents" field={formStateField!.amountInCents} />,
      };
    },
    w: "140px",
  });

  return [
    lotPartitionIdColumn,
    blueprintProjectColumn,
    addressColumn,
    drawAmountColumn,
    remainingDrawAmountColumn,
    drawDateColumn,
    amountColumn,
  ];
}

function mapToRows(lotPartitionDrawPayments: PaymentOverviewPageLotPartitionDrawPaymentFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lotPartitionDrawPayments);
}

type FormInput = SaveCreditFacilityDrawInput & {
  lotPartitionDrawPayments: Maybe<SaveLotPartitionDrawInput[]>;
};

function mapToForm(creditFacilityPayment: PaymentOverviewPagePaymentFragment): FormInput {
  return {
    id: creditFacilityPayment.id,
    lotPartitionDrawPayments: mapLotPartitionDrawPaymentsToForm(creditFacilityPayment.lotPartitionDrawPayments),
  };
}

export function mapLotPartitionDrawPaymentsToForm(
  lotPartitionDrawPayments: Maybe<PaymentOverviewPageLotPartitionDrawPaymentFragment[]>,
) {
  return lotPartitionDrawPayments?.map((lpd) => {
    return {
      ...lpd,
    };
  });
}

export const lotPartitionDrawConfig: ObjectConfig<SaveLotPartitionDrawInput> = {
  id: { type: "value" },
  amountInCents: { type: "value" },
};

const emptyCellDash = "-";

function formatCentsToPrice(valueInCents: number) {
  const isNegative = !!valueInCents && valueInCents < 0;
  const prefix = isNegative ? "-$" : "$";
  const formattedPrice =
    typeof valueInCents !== "number"
      ? emptyCellDash
      : `${prefix}${formatNumberToString(centsToDollars(Math.abs(valueInCents)), false)}`;
  return formattedPrice;
}
