import { Css, FullBleed, Icon, IconButton, Properties, useTestIds } from "@homebound/beam";
import { Fragment, ReactNode, ReactPortal, useEffect } from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import { usePropCoContext } from "src/contexts/PropCoContext";

// Not a fully flushed out component/layout for breadcrumbs. Currently only displaying more of a "Back" link.
export type HeaderBreadcrumb = {
  href: string;
  label: string;
};

type PageHeaderProps = {
  // Wrapped in an `h1` tag, and used as the `document.title`. If within a Project's context the Title will be suffixed with the project name
  title?: string;
  // Allows for hiding the title
  hideTitle?: boolean;
  breadcrumb?: HeaderBreadcrumb | HeaderBreadcrumb[];
  // Url the user should be redirected to when clicked
  backButton?: string;
  // Additional content that will show on the left of the header, but to the right of the breadcrumb and title (if supplied).
  left?: ReactNode;
  // Additional content to show on the right side of the screen. Typically actions.
  right?: ReactNode;
  // Allows for additional css to be added
  xss?: Properties;
};

/** Provides `header` element with a bottom border that extends the full width of its container */
export function PageHeader(props: PageHeaderProps) {
  const { headerActionsRef, headerActionsEl } = usePropCoContext();
  const { title, hideTitle, breadcrumb, backButton, right, left, xss, ...otherProps } = props;
  const tid = useTestIds(otherProps, "header");

  useEffect(() => {
    if (headerActionsRef && headerActionsEl) {
      headerActionsRef.current!.appendChild(headerActionsEl);
    }
  }, [headerActionsEl, headerActionsRef]);

  const actionSpacingStyles = Css.df.aic.fs0.gap1.$;

  return (
    <FullBleed>
      <header css={{ ...Css.df.gap3.aic.py2.mhPx(64).mb3.bb.bw1.bcGray200.bgWhite.ifMdAndDown.fdc.$, ...xss }} {...tid}>
        {/* if title or breadcrumbs exist, then we have a left. */}
        {(breadcrumb || backButton || (title && !hideTitle) || left) && (
          <div css={Css.fs0.mra.$}>
            {breadcrumb && (
              <div css={Css.if(!!backButton).mlPx(44).$}>
                <PageHeaderBreadcrumbs breadcrumb={breadcrumb} />
              </div>
            )}
            <div css={Css.df.aic.$}>
              {backButton && (
                // Applying `df` to ensure wrapping element stays at same height as the IconButton.
                // This is due to <IconButton /> being an inline element, which causes the wrapping element takes `line-height` into account. See https://stackoverflow.com/questions/11126685/why-does-container-div-insist-on-being-slightly-larger-than-img-or-svg-content
                <div css={Css.df.asfs.mr2.fs0.$}>
                  <IconButton icon="arrowBack" onClick={backButton} />
                </div>
              )}
              {!hideTitle && title && (
                <h1 css={Css.xlSb.mr3.$} {...tid.title}>
                  {title}
                </h1>
              )}
              <div css={Css.df.gap1.$}>{left}</div>
            </div>
          </div>
        )}
        <div css={Css.df.aic.fs0.mla.$}>
          {right && <div css={actionSpacingStyles}>{right}</div>}
          {/* Allows for appending more actions to the Page Header via a portal.
           Apply spacing styles to immediate child (headerActionsEl).
           Add left margin to the first child of the headerActionsEl, this avoids accidentally adding padding if not necessary to ensure everything sits flush to the right side of the page
          */}
          <div
            ref={headerActionsRef}
            css={Css.addIn("& > div", actionSpacingStyles).addIn("& > div > *:first-of-type", Css.ml1.$).$}
          />
        </div>
      </header>
    </FullBleed>
  );
}

export function PageHeaderBreadcrumbs({ breadcrumb }: { breadcrumb: HeaderBreadcrumb | HeaderBreadcrumb[] }) {
  const breadcrumbs = Array.isArray(breadcrumb) ? breadcrumb : [breadcrumb];

  return (
    <div css={Css.df.aic.mbPx(4).$}>
      {breadcrumbs.map((breadcrumb, i) => (
        <Fragment key={`bc-${breadcrumb.label}`}>
          {i > 0 && (
            <span css={Css.mr1.ml1.$}>
              <Icon icon="chevronRight" />
            </span>
          )}
          <Link to={breadcrumb.href} css={Css.xsMd.gray700.addIn("&:hover", Css.gray800.$).$} className="navLink">
            {breadcrumb.label}
          </Link>
        </Fragment>
      ))}
    </div>
  );
}

/** Helper component to append content into the PageHeader via a portal */
export function PageHeaderActions({ children }: { children: JSX.Element }): ReactPortal | JSX.Element {
  const { headerActionsEl } = usePropCoContext();

  // Fallback for testing purposes, if the headerActionsEl doesn't exist, just return the children so the tests can still find the elements they need
  if (!headerActionsEl) {
    return children;
  }

  return createPortal(children, headerActionsEl);
}
