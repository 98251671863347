import {
  Button,
  column,
  condensedStyle,
  Css,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  numericColumn,
  useModal,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { Percentage, Price } from "src/components";
import { getBlueprintBaseUrl, getSalesforceBaseUrl, getUnderwritingBaseUrl } from "src/context";
import { LotPartitionFragment } from "src/generated/graphql-types";
import { centsToDollars, formatNumberToString } from "src/utils";
import { DateOnly, formatWithYear } from "src/utils/dates";

const blueprintBaseUrl = getBlueprintBaseUrl();
const underwritingBaseUrl = getUnderwritingBaseUrl();
const salesforceBaseUrl = getSalesforceBaseUrl();

type ValuationModalProps = {
  lotPartition: LotPartitionFragment;
};

type PriceRow = {
  kind: "price";
  data: {
    label: string;
    price?: number | null;
    onClick?: () => void;
  };
};
type PercentRow = {
  kind: "percent";
  data: {
    label: string;
    percent?: number | null;
  };
};
type DateRow = {
  kind: "date";
  data: {
    label: string;
    date: DateOnly | undefined;
    onClick?: () => void;
  };
};

type Row = PriceRow | PercentRow | DateRow;

export function ValuationModal({ lotPartition }: ValuationModalProps) {
  const { closeModal } = useModal();

  return (
    <>
      <ModalHeader>Valuation Expectation</ModalHeader>
      <ModalBody>
        <GridTable
          style={{ ...condensedStyle, headerCellCss: Css.tinySb.gray700.py0.$ }}
          columns={[labelColumnMain, valueColumn]}
          rows={[
            {
              kind: "price",
              id: "soldPrice",
              data: {
                label: "Sold Price",
                price: lotPartition.salePriceInCents,
                onClick: lotPartition.salesforceOpportunityId
                  ? () =>
                      window.open(
                        `${salesforceBaseUrl}/Opportunity/${lotPartition.salesforceOpportunityId}/view`,
                        "_blank",
                        "noopener,noreferrer",
                      )
                  : undefined,
              },
            },
            {
              kind: "price",
              id: "netSoldPrice",
              data: {
                label: "Net Sold Price",
                price: lotPartition.realizedNetSalesPriceInCents,
              },
            },
            {
              kind: "price",
              id: "underContractPrice",
              data: {
                label: "Under Contract Price",
                price: lotPartition.underContractPriceInCents,
                onClick: lotPartition.salesforceOpportunityId
                  ? () =>
                      window.open(
                        `${salesforceBaseUrl}/Opportunity/${lotPartition.salesforceOpportunityId}/view`,
                        "_blank",
                        "noopener,noreferrer",
                      )
                  : undefined,
              },
            },
            {
              kind: "price",
              id: "netUnderContractPrice",
              data: {
                label: "Net Under Contract Price",
                price: lotPartition.underContractNetSalesPriceInCents,
              },
            },
            {
              kind: "price",
              id: "listPrice",
              data: {
                label: "List Price",
                price: lotPartition.listPriceInCents,
                onClick: lotPartition.salesforceHomeListingId
                  ? () =>
                      window.open(
                        `${salesforceBaseUrl}/Home_Listing__c/${lotPartition.salesforceHomeListingId}/view`,
                        "_blank",
                        "noopener,noreferrer",
                      )
                  : undefined,
              },
            },
            {
              kind: "price",
              id: "netlistPrice",
              data: {
                label: "Net Listed Price",
                price: lotPartition.listedNetSalesPriceInCents,
              },
            },
            {
              kind: "price",
              id: "expectedSalesPrice",
              data: {
                label: "Expected (Estimated) Sales Price (With Appreciation)",
                price: lotPartition.expectedSalePriceInCents,
                onClick: lotPartition.salesforceOpportunityId
                  ? () =>
                      window.open(
                        `${salesforceBaseUrl}/Opportunity/${lotPartition.salesforceOpportunityId}/view`,
                        "_blank",
                        "noopener,noreferrer",
                      )
                  : undefined,
              },
            },
            {
              kind: "date",
              id: "expectedEndAppreciationDate",
              data: {
                label: "Construction Completion Date",
                date: lotPartition.expectedCompletionDate || undefined,
                onClick: () => {
                  // Open the Blueprint Schedule in a new tab to show where this completion date came from
                  window.open(
                    `${blueprintBaseUrl}/projects/${lotPartition.blueprintProjectId}/schedule/construction`,
                    "_blank",
                    "noopener,noreferrer",
                  );
                },
              },
            },
            {
              kind: "price",
              id: "valuationSalesPrice",
              data: {
                label: "Valuation Sales Price (Without Appreciation)",
                price: lotPartition.salesPriceAtValuationDateInCents,
              },
            },
            {
              kind: "date",
              id: "valuationDate",
              data: {
                label: "Valuation Date",
                date: lotPartition.valuationDate,
                onClick:
                  lotPartition.lot.dpid && lotPartition.valuationReportId
                    ? () => {
                        // Open the Asset Valuation Report in the UW App to show where this comes from
                        window.open(
                          `${underwritingBaseUrl}/cma/${lotPartition.lot.dpid}/versions/${lotPartition.valuationReportId}/estimate`,
                          "_blank",
                          "noopener,noreferrer",
                        );
                      }
                    : undefined,
              },
            },
            {
              kind: "percent",
              id: "appreciationPercent",
              data: {
                label: "HPA From Valuation to Completion",
                percent: (lotPartition.appreciationMultiplier - 1) * 100_00, // Converting to percent change from the straight multiplier
              },
            },
          ]}
        />
      </ModalBody>
      <ModalFooter>
        <Observer>{() => <Button label="Close" onClick={closeModal} variant="secondary" />}</Observer>
      </ModalFooter>
    </>
  );
}

const labelColumnMain = column<Row>({
  w: "1fr",
  price: ({ label }) => label,
  percent: ({ label }) => label,
  date: ({ label }) => label,
});

const valueColumn = numericColumn<Row>({
  w: "120px",
  price: ({ price, onClick }) =>
    onClick ? (
      <Button label={formatNumberToString(centsToDollars(price || 0))} variant="tertiary" onClick={onClick} />
    ) : (
      <Price valueInCents={price} dropZero />
    ),
  percent: ({ percent }) => <Percentage percent={percent ? percent / 100 : 0} dropZero />,
  date: ({ date, onClick }) =>
    date ? <Button label={formatWithYear(date)} variant="tertiary" onClick={onClick || ""} disabled={!onClick} /> : "-",
});
