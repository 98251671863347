import { Css, Palette } from "@homebound/beam";
import { RouteProps } from "react-router-dom";
import { Footer } from "src/components/Footer";
import { Icon } from "src/components/Icon";
import { getQueryStringValue } from "src/utils/queryString";
import { LoginButton } from "./LoginButton";
import { UnauthedHeader } from "./UnauthedHeader";

export type SignInProps = RouteProps;

const maxErrorLength = 50;

export function SignIn(props: SignInProps) {
  const errorMessage = getQueryStringValue(props.location, "signInError")?.substr(0, maxErrorLength);

  function getMessage() {
    if (errorMessage) {
      return (
        <div css={Css.df.aic.$}>
          <div css={Css.pr1.$}>
            <Icon icon="alertError" inc={2} color={Palette.Red600} />{" "}
          </div>
          <div css={Css.red600.$}>{errorMessage}. Please try again.</div>
        </div>
      );
    }

    return <div>Please sign in to use this system.</div>;
  }

  return (
    <div css={Css.df.fdc.pt4.px4.mvh100.$}>
      <div css={Css.h("20vh").$}>
        <UnauthedHeader />
      </div>

      <div css={Css.fg1.asc.h("60vh").$}>
        <div css={Css.py2.xl3.tac.$}>
          <div css={Css.py5.$}>{getMessage()}</div>
          <LoginButton />
        </div>

        <div css={Css.pt1.$}>
          <a href="mailto:product-support@homebound.com">
            <span css={Css.gray800.$}>Need Help?</span>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}
