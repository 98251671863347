import {
  Button,
  column,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
} from "@homebound/beam";
import { Price } from "src/components";
import { FundOverviewPagePaymentFragment } from "src/generated/graphql-types";
import { createPaymentDetailsUrl } from "src/RouteUrls";
import { formatWithYear } from "src/utils/dates";
import { CreatePaymentModal } from "./CreatePaymentModal";

export type FundPaymentsTableProps = {
  payments: FundOverviewPagePaymentFragment[];
  fundId: string;
};

export function FundPaymentsTable(props: FundPaymentsTableProps) {
  const { payments, fundId } = props;

  const { openModal } = useModal();

  return (
    <>
      <GridTable
        id="paymentsTable"
        rowStyles={rowStyles}
        columns={createPaymentColumns()}
        rows={createPaymentRows(payments)}
        fallbackMessage="Payments for this Fund will show here."
      />
      <Button
        label="Create Payment"
        variant="secondary"
        onClick={() =>
          openModal({
            content: <CreatePaymentModal fundId={fundId} />,
          })
        }
      />
    </>
  );
}

type Row = SimpleHeaderAndData<FundOverviewPagePaymentFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {
    onClick: ({ data }) => {
      window.open(`${createPaymentDetailsUrl(data.id)}`, "_blank", "noopener,noreferrer");
    },
  },
};

function createPaymentRows(payments: FundOverviewPagePaymentFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(payments);
}

function createPaymentColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Credit Facility Payment ID",
    data: ({ id }) => id,
  });

  const paymentDateColumn = dateColumn<Row>({
    header: () => "Payment Date",
    data: ({ paymentDate }) => formatWithYear(paymentDate),
  });

  const amountColumn = column<Row>({
    header: () => "Amount Paid",
    data: ({ amountInCents }) => <Price valueInCents={amountInCents} dropZero />,
  });

  const allocatedAmountColumn = column<Row>({
    header: () => "Amount Allocated to Lot Partitions",
    data: ({ allocatedAmountInCents }) => <Price valueInCents={allocatedAmountInCents} dropZero />,
  });

  return [idColumn, paymentDateColumn, amountColumn, allocatedAmountColumn];
}
