/* eslint-disable no-empty-pattern */
import {
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridSortConfig,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { useMemo } from "react";
import { QueryTable } from "src/components";
import { LotsPageLotFragment, LotsPageLotsQuery, useLotsPageLotsQuery } from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { createLotDetailsUrl } from "src/RouteUrls";
import { formatNumberToString } from "src/utils";

export function LotsPage() {
  useDocumentTitle("Lots");

  // Hacktastic/Placeholder until we need to do an earlier user query
  return LotsDataView({});
}

type LotsDataViewProps = Record<string, never>;

export function LotsDataView({}: LotsDataViewProps) {
  const query = useLotsPageLotsQuery({ variables: {} });
  const initSortState: GridSortConfig = useMemo(() => ({ on: "client", initial: ["1", "ASC"] }), []);

  return (
    <>
      <div css={Css.mr2.$}>
        <ScrollableContent virtualized>
          <QueryTable
            stickyHeader
            sorting={initSortState}
            emptyFallback="No lots found."
            query={query}
            columns={columns}
            createRows={createRows}
            rowStyles={rowStyles}
            style={{ rowHeight: "fixed" }}
            as="virtual"
          />
        </ScrollableContent>
      </div>
    </>
  );
}

type Row = SimpleHeaderAndData<LotsPageLotFragment>;

const columns: GridColumn<Row>[] = [
  column<Row>({ header: "ID", data: (data) => data.id, w: "72px" }),
  column<Row>({
    header: "Address",
    data: (data) => data.address?.street1,
    w: "272px",
  }),
  column<Row>({
    header: "Investment Status",
    data: (data) => data.lotInvestmentStatus.name,
    w: "172px",
  }),
  column<Row>({
    header: "UW Investor Margin",
    data: (data) => formattedPercentage(data.underwrittenInvestorMarginBasisPoints / 100),
    w: "132px",
  }),
  column<Row>({
    header: "Current Investor Margin",
    data: (data) => formattedPercentage(data.investorMarginBasisPoints / 100),
    w: "172px",
  }),
  column<Row>({
    header: "SalesForce ID",
    data: (data) => data.salesforceOpportunityId,
    w: "172px",
  }),
  column<Row>({
    header: "Fund",
    data: (data) => data.fund?.name,
    w: "172px",
  }),
  column<Row>({ header: "UW dpid", data: (data) => data.dpid, w: "172px" }),
];

const emptyCellDash = "-";

function formattedPercentage(percent: number) {
  const formattedPercentage = typeof percent !== "number" ? emptyCellDash : `${formatNumberToString(percent, true)}%`;
  return formattedPercentage;
}

function createRows(data: LotsPageLotsQuery | undefined): GridDataRow<Row>[] {
  return [simpleHeader, ...(data?.lots.map((l) => ({ kind: "data" as const, id: l.id, data: l })) || [])];
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: { rowLink: ({ id }) => createLotDetailsUrl(id) },
};
