import { Palette } from "@homebound/beam";

export type LogoProps = {
  width?: number;
  height?: number;
  color?: Palette;
};

const defaultLogoWidth = 140;

export function HomeboundLogo(props: LogoProps) {
  // "auto" width if not set and user has a height set, otherwise fallback to default
  const width = props.width || (props.height ? "auto" : defaultLogoWidth);
  const color = props.color || Palette.Gray200;
  return logo(color, width, props.height || "auto");
}

export type HomeboundIconProps = {
  width?: number;
  height?: number;
  color?: Palette;
};

const defaultIconWidth = 36;

export function HomeboundIcon(props: HomeboundIconProps) {
  const color = props.color || Palette.Gray200;
  const width = props.width ? props.width : props.height ? "auto" : defaultIconWidth;
  return icon(color, width, props.height ? props.height : "auto");
}

function icon(fill: Palette, width: number | "auto", height: number | "auto") {
  return (
    <svg width={width} height={height} viewBox="0 0 158.1 97.6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M158.1,97.6H0.2L0,39.7L76.8,0l68,38.7l-3.1,5.3l-65-37L6.2,43.4l0.2,48h151.7V97.6z" fill={fill} />
    </svg>
  );
}

function logo(fill: string, width: number | "auto", height: number | "auto") {
  return (
    <svg width={width} height={height} viewBox="0 0 600 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M179.613 39.925H186.096V65.0822H221.529V39.925H227.938V97.8683H221.529V71.0962H186.096V97.8674H179.613V39.925V39.925Z"
        fill={fill}
      />
      <path
        d="M235.75 78.7241C235.75 66.9153 243.706 58.5538 254.903 58.5538C266.1 58.5538 274.056 66.9153 274.056 78.7241C274.056 90.6057 266.101 99.0408 254.903 99.0408C243.706 99.0408 235.75 90.6066 235.75 78.7241ZM267.648 78.7241C267.648 70.0697 262.344 63.9819 254.904 63.9819C247.463 63.9819 242.233 70.0697 242.233 78.7241C242.233 87.4522 247.463 93.6136 254.904 93.6136C262.343 93.6136 267.648 87.4522 267.648 78.7241Z"
        fill={fill}
      />
      <path
        d="M280.98 59.7275H287.316V66.6223C290.042 61.781 294.609 58.5538 300.945 58.5538C307.722 58.5538 312.069 61.4881 313.91 67.2819C317.004 61.9275 322.161 58.5538 328.643 58.5538C337.852 58.5538 342.64 64.1284 342.64 74.6899V97.8671H336.305V74.91C336.305 68.2354 332.99 63.9819 327.17 63.9819C319.877 63.9819 314.942 70.4363 314.942 79.3109V97.8671H308.606V74.91C308.606 68.2354 305.365 63.9819 299.546 63.9819C292.179 63.9819 287.317 70.4363 287.317 79.3109V97.8671H280.981V59.7275H280.98Z"
        fill={fill}
      />
      <path
        d="M349.725 78.7241C349.725 66.9153 357.68 58.5538 369.025 58.5538C378.013 58.5538 385.968 65.2284 385.968 78.0636V80.8504H356.281C356.797 88.4785 361.438 93.7593 368.878 93.7593C374.55 93.7593 378.381 90.3856 379.192 87.1583H385.453C384.274 93.5391 377.866 99.04 369.025 99.04C357.681 99.0409 349.725 90.6066 349.725 78.7241ZM379.633 75.7171C379.339 68.6758 374.845 63.9819 368.804 63.9819C361.732 63.9819 357.312 68.603 356.354 75.7171H379.633Z"
        fill={fill}
      />
      <path
        d="M399.951 91.5604V97.8683H393.615V39.925H399.951V66.0357C402.677 61.3418 407.097 58.5541 412.622 58.5541C422.714 58.5541 429.933 66.9155 429.933 78.6507C429.933 90.606 422.714 99.0411 412.622 99.0411C407.097 99.1148 402.677 96.2543 399.951 91.5604ZM423.597 78.7244C423.597 70.0699 418.662 63.9822 411.59 63.9822C404.592 63.9822 399.582 70.0699 399.582 78.7244C399.582 87.4525 404.592 93.6139 411.59 93.6139C418.662 93.6139 423.597 87.4525 423.597 78.7244Z"
        fill={fill}
      />
      <path
        d="M435.24 78.7241C435.24 66.9153 443.196 58.5538 454.393 58.5538C465.59 58.5538 473.546 66.9153 473.546 78.7241C473.546 90.6057 465.591 99.0408 454.393 99.0408C443.196 99.0408 435.24 90.6066 435.24 78.7241ZM467.137 78.7241C467.137 70.0697 461.833 63.9819 454.393 63.9819C446.953 63.9819 441.722 70.0697 441.722 78.7241C441.722 87.4522 446.953 93.6136 454.393 93.6136C461.833 93.6136 467.137 87.4522 467.137 78.7241Z"
        fill={fill}
      />
      <path
        d="M513.359 97.868H507.023V90.9733C504.297 95.8145 499.73 99.0409 493.394 99.0409C484.186 99.0409 479.397 93.4663 479.397 82.9048V59.7284H485.733V82.6855C485.733 89.3601 488.974 93.6136 494.867 93.6136C502.161 93.6136 507.022 87.1592 507.022 78.2846V59.7284H513.358V97.868H513.359Z"
        fill={fill}
      />
      <path
        d="M522.493 59.7275H528.829V66.6223C531.555 61.781 536.122 58.5538 542.457 58.5538C551.739 58.5538 556.454 64.1284 556.454 74.6899V97.8671H550.119V74.91C550.119 68.2354 546.877 63.9819 541.058 63.9819C533.692 63.9819 528.83 70.4363 528.83 79.3109V97.8671H522.494V59.7275H522.493Z"
        fill={fill}
      />
      <path
        d="M563.45 78.6515C563.45 66.9164 570.669 58.555 580.762 58.555C586.286 58.555 590.707 61.3418 593.433 66.0366V39.925H599.768V97.8683H593.433V91.5604C590.707 96.2542 586.287 99.1148 580.762 99.042C570.669 99.0411 563.45 90.6068 563.45 78.6515ZM593.801 78.7243C593.801 70.0699 588.791 63.9822 581.793 63.9822C574.721 63.9822 569.786 70.0699 569.786 78.7243C569.786 87.4524 574.721 93.6139 581.793 93.6139C588.791 93.6139 593.801 87.4524 593.801 78.7243Z"
        fill={fill}
      />
      <path
        d="M159.52 97.8374H1.63036L1.3916 39.9521L78.1557 0.230835L146.11 38.9012L143.05 44.2328L78.0157 7.22385L7.57379 43.6731L7.77202 91.697H159.52V97.8374Z"
        fill={fill}
      />
    </svg>
  );
}
