import { Css, ScrollableContent } from "@homebound/beam";
import { useParams } from "react-router";
import { useInvoiceOverviewInvoiceQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { formatWithYear } from "src/utils/dates";
import { queryResult } from "src/utils/queryResult";
import { InvoiceOverviewTabs } from "./InvoiceOverviewTabs";

export function InvoiceOverviewPage() {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const query = useInvoiceOverviewInvoiceQuery({ variables: { invoiceId } });

  return queryResult(query, {
    data: (data) => {
      const { creditFacilityInvoices } = data;
      const invoice = creditFacilityInvoices[0];
      const title = `${invoice.fund.name} - Invoice ${invoice.id} - ${formatWithYear(invoice.invoiceDate)}`;
      return (
        <>
          <PageHeader title={title} />
          <ScrollableContent>
            <div css={Css.df.py2.aifs.jcsb.$} data-testid="drawOverviewPage">
              <div css={Css.fg1.$}>
                <InvoiceOverviewTabs invoice={invoice} />
              </div>
            </div>
          </ScrollableContent>
        </>
      );
    },
  });
}
