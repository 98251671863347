import { column, condensedStyle, Css, GridTable, numericColumn, simpleHeader, useTestIds } from "@homebound/beam";
import { Percentage, Price } from "src/components";
import { LotInvestorMarginFragment } from "src/generated/graphql-types";

type InvestorMarginDetailsProps = {
  marginData: LotInvestorMarginFragment | undefined;
};

type HeaderRow = { kind: "header" };
type PriceRow = {
  kind: "price";
  data: {
    label: string;
    currentPrice?: number | null;
    underwrittenPrice?: number | null;
  };
};
type PercentRow = {
  kind: "percent";
  data: {
    label: string;
    currentPercent?: number | null;
    underwrittenPercent?: number | null;
  };
};
type Row = HeaderRow | PriceRow | PercentRow;

export function InvestorMarginDetails({ marginData }: InvestorMarginDetailsProps) {
  const testIds = useTestIds({});

  return (
    <div {...testIds.financialsTable}>
      <GridTable
        style={{ ...condensedStyle, headerCellCss: Css.tinySb.gray700.py0.$ }}
        columns={[labelColumnMain, underwrittenValueColumn, currentValueColumn]}
        rows={[
          simpleHeader,
          {
            kind: "price",
            id: "purchasePrice",
            data: {
              label: "Purchase Price",
              currentPrice: marginData?.purchasePriceInCents,
              underwrittenPrice: marginData?.underwrittenTotalLotAcquisitionCostsInCents,
            },
          },
          {
            kind: "price",
            id: "siteMarkupCost",
            data: {
              label: "HB Site Markup Cost",
              currentPrice: marginData?.opcoSiteAcquisitionMarkupInCents,
              underwrittenPrice: marginData?.opcoSiteAcquisitionMarkupInCents,
            },
          },
          {
            kind: "price",
            id: "softCost",
            data: {
              label: "Soft Cost",
              currentPrice: marginData?.softCostInCents,
              underwrittenPrice: marginData?.underwrittenSoftCostInCents,
            },
          },
          {
            kind: "price",
            id: "directHardCost",
            data: {
              label: "Direct Hard Cost",
              currentPrice: marginData?.directHardCostInCents,
              underwrittenPrice: marginData?.underwrittenDirectHardCostInCents,
            },
          },
          {
            kind: "price",
            id: "indirectHardCost",
            data: {
              label: "Indirect Hard Cost",
              currentPrice: marginData?.indirectHardCostInCents,
              underwrittenPrice: marginData?.underwrittenIndirectHardCostInCents,
            },
          },
          {
            kind: "price",
            id: "OpcoMarkup",
            data: {
              label: "Opco Markup",
              currentPrice: marginData?.opcoMarkupInCents,
              underwrittenPrice: marginData?.underwrittenOpcoMarkupInCents,
            },
          },
          {
            kind: "price",
            id: "financingCost",
            data: {
              label: "Financing Cost",
              currentPrice: marginData?.financingCostInCents,
              underwrittenPrice: marginData?.underwrittenFinancingCostInCents,
            },
          },
          {
            kind: "price",
            id: "totalCost",
            data: {
              label: "Total Cost",
              currentPrice: marginData?.totalCostInCents,
              underwrittenPrice: marginData?.underwrittenTotalCostInCents,
            },
          },
          {
            kind: "price",
            id: "totalSalesValue",
            data: {
              label: "Total Sales Value",
              currentPrice: marginData?.totalSaleValueInCents,
              underwrittenPrice: marginData?.underwrittenTotalSaleValueInCents,
            },
          },
          {
            kind: "price",
            id: "totalNetRevenue",
            data: {
              label: "Total Net Revenue",
              currentPrice: marginData?.totalNetSaleRevenueInCents,
              underwrittenPrice: marginData?.underwrittenTotalNetSaleRevenueInCents,
            },
          },
          {
            kind: "percent",
            id: "investorMargin",
            data: {
              label: "Investor Margin",
              currentPercent: marginData?.investorMarginBasisPoints,
              underwrittenPercent: marginData?.underwrittenInvestorMarginBasisPoints,
            },
          },
        ]}
      />
    </div>
  );
}

const labelColumnMain = column<Row>({
  w: "1fr",
  header: "",
  price: ({ label }) => label,
  percent: ({ label }) => label,
});

const currentValueColumn = numericColumn<Row>({
  w: "120px",
  header: "Current",
  price: ({ currentPrice }) => getPriceComponent(currentPrice || undefined),
  percent: ({ currentPercent }) => getPercentComponent(currentPercent || undefined),
});
const underwrittenValueColumn = numericColumn<Row>({
  w: "120px",
  header: "Underwritten",
  price: ({ underwrittenPrice }) => getPriceComponent(underwrittenPrice || undefined),
  percent: ({ underwrittenPercent }) => getPercentComponent(underwrittenPercent || undefined),
});

function getPriceComponent(value: number | undefined) {
  return <Price valueInCents={value} dropZero />;
}

function getPercentComponent(value: number | undefined) {
  const percent = value ? value / 100 : 0;
  return <Percentage percent={percent} dropZero />;
}
