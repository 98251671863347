import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { DrawOverviewPageDrawFragment } from "src/generated/graphql-types";
import { DrawDetails } from "./DrawDetails";
import { DrawLotPartitionDrawsTable } from "./DrawLotPartitionDrawsTable";

export type DrawOverviewTabsProps = {
  draw: DrawOverviewPageDrawFragment;
};

export function DrawOverviewTabs({ draw }: DrawOverviewTabsProps) {
  const [currentTab, setCurrentTab] = useState("Activity Feed");

  const tabs = getTabs({ draw });

  return <TabsWithContent selected={currentTab} onChange={setCurrentTab} tabs={tabs} />;
}

function getTabs(props: DrawOverviewTabsProps) {
  const { draw } = props;

  return [
    {
      name: "Draw Information",
      value: "DrawInformation",
      render: () => <DrawDetails draw={draw} />,
    },
    {
      name: "Lot Partition Draws",
      value: "LotPartitionDraws",
      render: () => <DrawLotPartitionDrawsTable draw={draw} lotPartitionDraws={draw.lotPartitionDraws} />,
    },
  ];
}
