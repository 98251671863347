import {
  BoundNumberField,
  column,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import { Price } from "src/components";
import {
  InvoiceOverviewPageDrawInterestFragment,
  InvoiceOverviewPageInvoiceFragment,
  Maybe,
  SaveCreditFacilityInvoiceDrawInterestInput,
  useSaveDrawInterestMutation,
} from "src/generated/graphql-types";
import { formatWithYear } from "src/utils/dates";

type InvoiceDrawInterestTableProps = {
  invoice: InvoiceOverviewPageInvoiceFragment;
  drawInterests: InvoiceOverviewPageDrawInterestFragment[];
};

export type SaveInvoiceDrawInterestDetails = SaveCreditFacilityInvoiceDrawInterestInput;

export function InvoiceDrawInterestTable(props: InvoiceDrawInterestTableProps) {
  const { invoice, drawInterests } = props;

  const formConfig: ObjectConfig<FormInput> = useMemo(
    () => ({
      id: { type: "value" },
      drawInterests: {
        type: "list",
        config: invoiceDrawInterestConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      input: invoice,
      map: mapToForm,
    },
    autoSave: saveDrawInterests,
  });
  const [saveDrawInterest] = useSaveDrawInterestMutation();

  async function saveDrawInterests(formState: ObjectState<FormInput>) {
    const changedLotPartitionDraws =
      formState.changedValue.drawInterests?.filter((lpd) => lpd.amountInCents !== undefined) || [];
    await Promise.all(
      changedLotPartitionDraws.map(async (lpd) =>
        saveDrawInterest({
          variables: { input: { ...lpd } },
        }),
      ),
    );
  }

  const columns = createColumns(drawInterests, formState);
  const rows = mapToRows(drawInterests);

  return (
    <GridTable
      id="invoiceDrawInterestsTable"
      columns={columns}
      rows={rows}
      rowStyles={rowStyles}
      // sorting={{ on: "client" }}
      stickyHeader
      style={{ rowHeight: "flexible" }}
    />
  );
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

type Row = SimpleHeaderAndData<InvoiceOverviewPageDrawInterestFragment>;

function createColumns(
  drawInterests: InvoiceOverviewPageDrawInterestFragment[],
  formState: ObjectState<FormInput>,
): GridColumn<Row>[] {
  const lotPartitionIdColumn = column<Row>({
    header: "Lot Partition",
    data: (row) => row.lotPartitionDraw.lotPartition.id,
    w: "100px",
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: "Address",
    data: (row) => row.lotPartitionDraw.lotPartition.lot.address?.street1,
    w: "150px",
    sticky: "left",
  });

  const blueprintProjectColumn = column<Row>({
    header: "Blueprint Project",
    data: (row) => row.lotPartitionDraw.lotPartition.blueprintProjectId,
    w: "100px",
    sticky: "left",
  });

  const drawDateColumn = dateColumn<Row>({
    header: () => "Draw Date",
    w: "100px",
    data: (row) => formatWithYear(row.lotPartitionDraw.creditFacilityDraw.drawDate),
  });

  const drawAmountColumn = column<Row>({
    header: () => "Original Draw Amount",
    w: "100px",
    data: (row) => <Price valueInCents={row.lotPartitionDraw.amountInCents} dropZero />,
  });

  const outstandingDrawAmountColumn = column<Row>({
    header: () => "Draw Amount At Interest Date",
    w: "100px",
    data: (row) => <Price valueInCents={row.drawAmountInCents} dropZero />,
  });

  const interestDateColumn = dateColumn<Row>({
    header: () => "Interest Date",
    w: "100px",
    data: (row) => formatWithYear(row.interestDate),
  });

  const amountColumn = numericColumn<Row>({
    header: () => "Draw Interest Amount",
    data: (row) => {
      const formStateField = formState.drawInterests.rows.find((di) => di.id.value === row.id);
      return {
        value: () => row.amountInCents,
        content: () => <BoundNumberField type="cents" field={formStateField!.amountInCents} />,
      };
    },
    w: "140px",
  });

  return [
    lotPartitionIdColumn,
    blueprintProjectColumn,
    addressColumn,
    drawDateColumn,
    drawAmountColumn,
    outstandingDrawAmountColumn,
    interestDateColumn,
    amountColumn,
  ];
}

function mapToRows(lotPartitionDraws: InvoiceOverviewPageDrawInterestFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lotPartitionDraws);
}

type FormInput = SaveCreditFacilityInvoiceDrawInterestInput & {
  drawInterests: Maybe<SaveCreditFacilityInvoiceDrawInterestInput[]>;
};

function mapToForm(creditFacilityInvoice: InvoiceOverviewPageInvoiceFragment): FormInput {
  return {
    id: creditFacilityInvoice.id,
    drawInterests: mapLotPartitionDrawsToForm(creditFacilityInvoice.drawInterests),
  };
}

export function mapLotPartitionDrawsToForm(drawInterests: Maybe<InvoiceOverviewPageDrawInterestFragment[]>) {
  return drawInterests?.map((di) => {
    return {
      ...di,
    };
  });
}

export const invoiceDrawInterestConfig: ObjectConfig<SaveInvoiceDrawInterestDetails> = {
  id: { type: "value" },
  amountInCents: { type: "value" },
};
