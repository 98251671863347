import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { InvoiceOverviewPageInvoiceFragment } from "src/generated/graphql-types";
import { InvoiceDetails } from "./InvoiceDetails";
import { InvoiceDrawInterestTable } from "./InvoiceDrawInterestTable";
import { InvoiceDrawInterestGroupedTable } from "./InvoiceDrawInterestGroupedTable";

export type InvoiceOverviewTabsProps = {
  invoice: InvoiceOverviewPageInvoiceFragment;
};

export function InvoiceOverviewTabs({ invoice }: InvoiceOverviewTabsProps) {
  const [currentTab, setCurrentTab] = useState("Credit Facility Invoice");

  const tabs = getTabs({ invoice });

  return <TabsWithContent selected={currentTab} onChange={setCurrentTab} tabs={tabs} />;
}

function getTabs(props: InvoiceOverviewTabsProps) {
  const { invoice } = props;

  return [
    {
      name: "Invoice Information",
      value: "InvoiceInformation",
      render: () => <InvoiceDetails invoice={invoice} />,
    },
    {
      name: "Invoice Draw Interest - Daily",
      value: "InvoiceDrawInterestDaily",
      render: () => <InvoiceDrawInterestTable invoice={invoice} drawInterests={invoice.drawInterests} />,
    },
    {
      name: "Invoice Draw Interest - Grouped",
      value: "InvoiceDrawInterestGrouped",
      render: () => (
        <InvoiceDrawInterestGroupedTable
          invoice={invoice}
          groupedDrawInterests={invoice.drawInterestsByLotPartitionDraw}
        />
      ),
    },
  ];
}
