import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { PaymentOverviewPagePaymentFragment } from "src/generated/graphql-types";
import { PaymentDetails } from "./PaymentDetails";
import { PaymentLotPartitionDrawPaymentsTable } from "./PaymentLotPartitionDrawPaymentsTable";

export type PaymentOverviewTabsProps = {
  payment: PaymentOverviewPagePaymentFragment;
};

export function PaymentOverviewTabs({ payment }: PaymentOverviewTabsProps) {
  const [currentTab, setCurrentTab] = useState("Activity Feed");

  const tabs = getTabs({ payment });

  return <TabsWithContent selected={currentTab} onChange={setCurrentTab} tabs={tabs} />;
}

function getTabs(props: PaymentOverviewTabsProps) {
  const { payment } = props;

  return [
    {
      name: "Payment Information",
      value: "PaymentInformation",
      render: () => <PaymentDetails payment={payment} />,
    },
    {
      name: "Lot Partition Draw Payments",
      value: "LotPartitionDrawPayments",
      render: () => (
        <PaymentLotPartitionDrawPaymentsTable
          payment={payment}
          lotPartitionDrawPayments={payment.lotPartitionDrawPayments}
        />
      ),
    },
  ];
}
