import { Css, ScrollableContent } from "@homebound/beam";
import { useParams } from "react-router";
import { useDrawOverviewDrawQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { formatWithYear } from "src/utils/dates";
import { queryResult } from "src/utils/queryResult";
import { DrawOverviewTabs } from "./DrawOverviewTabs";

export function DrawOverviewPage() {
  const { drawId } = useParams<{ drawId: string }>();
  const query = useDrawOverviewDrawQuery({ variables: { drawId } });

  return queryResult(query, {
    data: (data) => {
      const { creditFacilityDraws } = data;
      const draw = creditFacilityDraws[0];
      const title = `${draw.fund.name} - Draw ${draw.id} - ${formatWithYear(draw.drawDate)}`;
      return (
        <>
          <PageHeader title={title} />
          <ScrollableContent>
            <div css={Css.df.py2.aifs.jcsb.$} data-testid="drawOverviewPage">
              <div css={Css.fg1.$}>
                <DrawOverviewTabs draw={draw} />
              </div>
            </div>
          </ScrollableContent>
        </>
      );
    },
  });
}
