import {
  BoundNumberField,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import {
  FundOverviewPageFundFragment,
  FundOverviewPageFundMetroFragment,
  Maybe,
  SaveFundMetroInput,
  useSaveFundMetroMutation,
} from "src/generated/graphql-types";

export type FundMetrosTableProps = {
  metros: FundOverviewPageFundMetroFragment[];
  fund: FundOverviewPageFundFragment;
};

export type SaveFundMetroDetails = SaveFundMetroInput;

export function FundMetrosTable(props: FundMetrosTableProps) {
  const { metros, fund } = props;

  const [saveFundMetroMutation] = useSaveFundMetroMutation();

  const formConfigMetro: ObjectConfig<FormInput> = useMemo(
    () => ({
      fundMetros: {
        type: "list",
        config: fundMetroConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfigMetro,
    init: {
      input: fund,
      map: mapToMetroForm,
    },
    autoSave: saveFundMetros,
  });

  async function saveFundMetros(formState: ObjectState<FormInput>) {
    const changedFundMetros =
      formState.changedValue.fundMetros?.filter((fm) => fm.targetInvestorMarginBasisPoints !== undefined) || [];
    await Promise.all(
      changedFundMetros.map(async (fm) =>
        saveFundMetroMutation({
          variables: { input: { ...fm } },
        }),
      ),
    );
  }

  return (
    <>
      <GridTable
        id="fundMetrosTable"
        rowStyles={rowStyles}
        columns={createFundMetroColumns(metros, formState)}
        rows={createFundMetroRows(metros)}
        fallbackMessage="Fund Metros for this Fund will show here."
      />
    </>
  );
}

type Row = SimpleHeaderAndData<FundOverviewPageFundMetroFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createFundMetroRows(metros: FundOverviewPageFundMetroFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(metros);
}

function createFundMetroColumns(
  metros: FundOverviewPageFundMetroFragment[],
  formState: ObjectState<FormInput>,
): GridColumn<Row>[] {
  const fundMetroIdColumn = column<Row>({
    header: () => "Id",
    data: ({ id }) => id,
  });

  const metroNameColumn = column<Row>({
    header: () => "Metro",
    data: ({ metro }) => metro.name,
  });

  const targetInvestorMarginColumn = column<Row>({
    header: () => "Target Investor Margin",
    data: (row) => {
      const formStateField = formState.fundMetros.rows.find((fm) => fm.id.value === row.id);
      return {
        value: () => row.targetInvestorMarginBasisPoints,
        content: () => <BoundNumberField type="basisPoints" field={formStateField!.targetInvestorMarginBasisPoints} />,
      };
    },
  });

  return [fundMetroIdColumn, metroNameColumn, targetInvestorMarginColumn];
}

function mapToMetroForm(fund: FundOverviewPageFundFragment): FormInput {
  return {
    id: fund.id,
    fundMetros: mapFundMetrosToForm(fund.metros),
  };
}

export function mapFundMetrosToForm(fundMetros: Maybe<FundOverviewPageFundMetroFragment[]>) {
  return fundMetros?.map((fm) => {
    return {
      ...fm,
    };
  });
}

type FormInput = SaveFundMetroDetails & {
  fundMetros: Maybe<SaveFundMetroDetails[]>;
};

export const fundMetroConfig: ObjectConfig<SaveFundMetroDetails> = {
  id: { type: "value" },
  targetInvestorMarginBasisPoints: { type: "value", rules: [required] },
};
