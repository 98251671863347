import { BoundNumberField, Css, IconButton, Palette } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import { useHistory } from "react-router";
import {
  DrawOverviewPageDrawFragment,
  SaveCreditFacilityDrawInput,
  useDeleteDrawMutation,
  useDrawOverviewDrawQuery,
  useSaveCreditFacilityDrawMutation,
} from "src/generated/graphql-types";
import { createFundDetailsUrl } from "src/RouteUrls";

type DrawDetailsProps = {
  draw: DrawOverviewPageDrawFragment;
};

export type SaveDrawDetails = {
  fixedRateDisplay: number | null | undefined;
} & SaveCreditFacilityDrawInput;

export function DrawDetails({ draw }: DrawDetailsProps) {
  const query = useDrawOverviewDrawQuery({
    variables: { drawId: draw.id },
  });
  const [saveDrawMutation] = useSaveCreditFacilityDrawMutation();
  const [deleteDrawMutation] = useDeleteDrawMutation();
  const history = useHistory();

  const formConfig: ObjectConfig<SaveDrawDetails> = useMemo(
    () => ({
      id: { type: "value" },
      fixedRateDisplay: { type: "value", rules: [required] },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      query,
      map: ({ creditFacilityDraws: [draw] }) => ({
        ...draw,
        // Convert the rate from 1000ths of Basis Points to a percentage.
        fixedRateDisplay: draw.fixedRateThousandthsBasisPoints / 1000_00,
      }),
    },
    autoSave: saveDraw,
  });

  async function saveDraw() {
    await saveDrawMutation({
      variables: {
        input: {
          id: formState.value.id,
          // Convert from the displayed percentage to 1000ths of Basis Points so we can store it
          fixedRateThousandthsBasisPoints: formState.value.fixedRateDisplay
            ? Math.round(formState.value.fixedRateDisplay * 1000_00)
            : undefined,
        },
      },
    });
  }

  const deleteDisabled = !draw.canDelete.allowed;
  const deleteDisabledReasons = draw.canDelete.disabledReasons.map((reason) => reason.message).join("; ");
  return (
    <div css={Css.df.aic.$}>
      <div css={Css.df.wPx(400).fs0.fdc.p2.bgGray100.$}>
        <BoundNumberField field={formState.fixedRateDisplay} type="percent" label="Fixed Rate" numFractionDigits={5} />
        <IconButton
          icon="trash"
          data-testid={`delete`}
          onClick={async () => {
            // Delete this draw
            await deleteDrawMutation({
              variables: { input: { id: draw.id } },
            });
            // And redirect back to the parent page
            history.push({ pathname: createFundDetailsUrl(draw.fund.id) });
          }}
          color={deleteDisabled ? Palette.Gray400 : Palette.Red400}
          disabled={deleteDisabled}
          tooltip={deleteDisabled ? deleteDisabledReasons : "Delete this Draw"}
        />
      </div>
    </div>
  );
}
