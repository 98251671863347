import { Css, ScrollableContent } from "@homebound/beam";
import { useParams } from "react-router";
import { usePaymentOverviewPaymentQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { formatWithYear } from "src/utils/dates";
import { queryResult } from "src/utils/queryResult";
import { PaymentOverviewTabs } from "./PaymentOverviewTabs";

export function PaymentOverviewPage() {
  const { paymentId } = useParams<{ paymentId: string }>();
  const query = usePaymentOverviewPaymentQuery({ variables: { paymentId } });

  return queryResult(query, {
    data: (data) => {
      const { creditFacilityPayments } = data;
      const payment = creditFacilityPayments[0];
      const title = `${payment.fund.name} - Payment ${payment.id} - ${formatWithYear(payment.paymentDate)}`;
      return (
        <>
          <PageHeader title={title} />
          <ScrollableContent>
            <div css={Css.df.py2.aifs.jcsb.$} data-testid="paymentOverviewPage">
              <div css={Css.fg1.$}>
                <PaymentOverviewTabs payment={payment} />
              </div>
            </div>
          </ScrollableContent>
        </>
      );
    },
  });
}
