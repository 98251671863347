import { createContext, MutableRefObject, PropsWithChildren, useContext, useMemo, useRef } from "react";

type PropCoContextProps = {
  headerActionsRef: MutableRefObject<HTMLDivElement | null> | null;
  headerActionsEl: HTMLDivElement | null;
};

export const PropCoContext = createContext<PropCoContextProps>({
  headerActionsRef: null,
  headerActionsEl: null,
});

export function PropCoProvider({ children }: PropsWithChildren<unknown>) {
  const headerActionsRef = useRef<HTMLDivElement | null>(null);
  const headerActionsEl = useMemo(() => document.createElement("div"), []);
  const context: PropCoContextProps = { headerActionsRef, headerActionsEl };

  return <PropCoContext.Provider value={context}>{children}</PropCoContext.Provider>;
}

export function usePropCoContext() {
  return useContext(PropCoContext);
}
