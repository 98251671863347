import {
  BoundNumberField,
  BoundTextField,
  Button,
  column,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  ModalProps,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import { getBlueprintBaseUrl, getUnderwritingBaseUrl } from "src/context";
import {
  LotOverviewPageLotFragment,
  LotPartitionFragment,
  Maybe,
  SaveLotPartitionInput,
  useSaveLotPartitionsMutation,
} from "src/generated/graphql-types";
import { centsToDollars, formatNumberToString } from "src/utils";
import { DateOnly, formatWithYear } from "src/utils/dates";
import { ValuationModal } from "./ValuationModal";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";

export type LotPartitionsTableProps = {
  lotPartitions: LotPartitionFragment[];
  lot: LotOverviewPageLotFragment;
};

export function LotPartitionsTable(props: LotPartitionsTableProps) {
  const { lot, lotPartitions } = props;
  const { openModal } = useModal();

  const formConfig: ObjectConfig<FormInput> = useMemo(
    () => ({
      id: { type: "value" },
      lotPartitions: {
        type: "list",
        config: lotPartitionConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      input: lot,
      map: mapToForm,
    },
    autoSave: saveLotPartitions,
  });
  const [saveLotPartition] = useSaveLotPartitionsMutation();

  async function saveLotPartitions(formState: ObjectState<FormInput>) {
    const changedLotPartitions =
      formState.changedValue.lotPartitions?.filter(
        (lp) =>
          lp.lotOwnershipBasisPoints !== undefined ||
          lp.notes !== undefined ||
          lp.appraisalValueInCents !== undefined ||
          lp.appraisalDate !== undefined,
      ) || [];
    await Promise.all(
      changedLotPartitions.map(async (lp) =>
        saveLotPartition({
          variables: { input: { lotPartitions: [{ ...lp }] } },
        }),
      ),
    );
  }

  return (
    <>
      <GridTable
        id="lotPartitionsTable"
        rowStyles={rowStyles}
        columns={createPartitionColumns(formState, openModal)}
        rows={createPartitionRows(lotPartitions)}
        fallbackMessage="Partitions for this Lot will show here."
      />
    </>
  );
}

type Row = SimpleHeaderAndData<LotPartitionFragment>;

const blueprintBaseUrl = getBlueprintBaseUrl();
const underwritingBaseUrl = getUnderwritingBaseUrl();

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createPartitionRows(lotPartitions: LotPartitionFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lotPartitions);
}

function createPartitionColumns(
  formState: ObjectState<FormInput>,
  openModal: { (props: ModalProps): void },
): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Partition ID",
    data: ({ id }) => id,
    w: "100px",
  });

  const underwrittenPriceColumn = column<Row>({
    header: () => "UW Sales Price (With Appreciation)",
    data: (data) => {
      return (
        <Button
          label={formatCentsToPrice(data.underwrittenSalesPriceInCents)}
          variant="tertiary"
          onClick={() => {
            // Open the Underwriting Clear to Close report from which this came
            window.open(
              `${underwritingBaseUrl}/cma/${data.lot.dpid}/versions/${data.lot.clearToCloseUwReportId}/estimate`,
              "_blank",
              "noopener,noreferrer",
            );
          }}
          disabled={!data.lot.clearToCloseUwReportId || !data.lot.dpid}
          tooltip={
            data.isLegacyUwRevenue
              ? "This number might not align with linked UW Report as it was set from the legacy Central Margin Tracker Spreadsheet"
              : "Sourced from linked UW Report"
          }
        />
      );
    },
    w: "300px",
  });

  const expectedPriceColumn = column<Row>({
    header: () => "Current Expected Sales Price (With Appreciation)",
    data: (data) => {
      return (
        <Button
          label={formatCentsToPrice(data.netSalesPriceInCents)}
          variant="tertiary"
          onClick={() => {
            openModal({
              content: <ValuationModal lotPartition={data} />,
            });
          }}
        />
      );
    },
    w: "300px",
  });

  const statusColumn = column<Row>({
    header: () => "Sales Status",
    data: ({ lotPartitionSalesStatus }) => lotPartitionSalesStatus.name,
  });

  const totalOpcoCostColumn = column<Row>({
    header: () => "Current OpCo Cost",
    data: ({ opcoTotalCostInCents, blueprintProjectId }) => {
      return (
        <Button
          label={formatCentsToPrice(opcoTotalCostInCents)}
          variant="tertiary"
          onClick={() => {
            // Open the Blueprint contracts list (with empty filters to get them all) in a new tab
            window.open(
              `${blueprintBaseUrl}/projects/${blueprintProjectId}/homeowner-contracts?filter=%7B%7D`,
              "_blank",
              "noopener,noreferrer",
            );
          }}
        />
      );
    },
    w: "300px",
  });

  const underwrittenTotalOpcoPriceColumn = column<Row>({
    header: () => "UW OpCo Cost",
    data: ({ underwrittenOpcoTotalCostInCents }) => formatCentsToPrice(underwrittenOpcoTotalCostInCents),
    w: "300px",
  });

  const lotOwnershipPercentageColumn = column<Row>({
    header: () => "Lot Ownership %",
    data: (row) => {
      const formStateField = formState.lotPartitions.rows.find((lp) => lp.id.value === row.id);
      return {
        value: () => row.lotOwnershipBasisPoints,
        content: () => <BoundNumberField type="basisPoints" field={formStateField!.lotOwnershipBasisPoints} />,
        tooltip:
          "The percentage of the lot that this partition owns, as defined by the HOA agreement - required to sum to 100% by the time the first lot partition is sold",
      };
    },
    w: "100px",
  });

  // This is now auto-populated from Salesforce, so we don't need to edit it
  const soldDateColumn = dateColumn<Row>({
    header: () => "Sold Date",
    data: ({ saleDate }) => (saleDate ? formatWithYear(saleDate) : emptyCellDash),
    w: "100px",
  });

  const notesColumn = column<Row>({
    header: () => "Notes",
    data: (row) => {
      const formStateField = formState.lotPartitions.rows.find((lp) => lp.id.value === row.id);
      return {
        value: () => row.notes,
        content: () => <BoundTextField field={formStateField!.notes} />,
      };
    },
    w: "200px",
  });

  const appraisalColumn = column<Row>({
    header: () => "Appraised Value",
    data: (row) => {
      const formStateField = formState.lotPartitions.rows.find((lp) => lp.id.value === row.id);
      return {
        value: () => row.appraisalValueInCents,
        content: () => <BoundNumberField type="cents" field={formStateField!.appraisalValueInCents} />,
        tooltip: "The most recent appraised value for this property",
      };
    },
    w: "200px",
  });

  const appraisalDateColumn = column<Row>({
    header: () => "Appraised Date",
    data: (row) => {
      const formStateField = formState.lotPartitions.rows.find((lp) => lp.id.value === row.id);
      return {
        value: () => row.appraisalDate,
        content: () => <BoundBeamDateField field={formStateField!.appraisalDate} />,
        tooltip: "The date of the most recent appraisal for this property",
      };
    },
    w: "150px",
  });

  return [
    idColumn,
    underwrittenPriceColumn,
    underwrittenTotalOpcoPriceColumn,
    expectedPriceColumn,
    totalOpcoCostColumn,
    lotOwnershipPercentageColumn,
    soldDateColumn,
    statusColumn,
    notesColumn,
    appraisalColumn,
    appraisalDateColumn,
  ];
}

const emptyCellDash = "-";

function formatCentsToPrice(valueInCents: number) {
  const isNegative = !!valueInCents && valueInCents < 0;
  const prefix = isNegative ? "-$" : "$";
  const formattedPrice =
    typeof valueInCents !== "number"
      ? emptyCellDash
      : `${prefix}${formatNumberToString(centsToDollars(Math.abs(valueInCents)), false)}`;
  return formattedPrice;
}

type FormInput = SaveLotPartitionInput & {
  lotPartitions: Maybe<SaveLotPartitionInput[]>;
};

function mapToForm(lot: LotOverviewPageLotFragment): FormInput {
  return {
    id: lot.id,
    lotPartitions: mapLotPartitionsToForm(lot.partitions),
  };
}

export function mapLotPartitionsToForm(lotPartitions: Maybe<LotPartitionFragment[]>) {
  return lotPartitions?.map((lp) => {
    return {
      id: lp.id,
      lotOwnershipBasisPoints: lp.lotOwnershipBasisPoints,
      saleDate: lp.saleDate ? new DateOnly(lp.saleDate) : undefined,
      notes: lp.notes,
      appraisalValueInCents: lp.appraisalValueInCents,
      appraisalDate: lp.appraisalDate ? new DateOnly(lp.appraisalDate) : undefined,
    };
  });
}

type FormValueRevision = Pick<
  SaveLotPartitionInput,
  "id" | "lotOwnershipBasisPoints" | "saleDate" | "notes" | "appraisalValueInCents"
> & {
  appraisalDate: DateOnly | null | undefined;
};

export const lotPartitionConfig: ObjectConfig<FormValueRevision> = {
  id: { type: "value" },
  lotOwnershipBasisPoints: { type: "value", rules: [required] },
  saleDate: { type: "value", rules: [] },
  notes: { type: "value", rules: [] },
  appraisalValueInCents: { type: "value" },
  appraisalDate: { type: "value" },
};
