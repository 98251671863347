import {
  BoundNumberField,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import {
  DrawOverviewPageDrawFragment,
  DrawOverviewPageLotPartitionDrawFragment,
  Maybe,
  SaveCreditFacilityDrawInput,
  SaveLotPartitionDrawInput,
  useSaveLotPartitionDrawMutation,
} from "src/generated/graphql-types";

type DrawLotPartitionDrawsTableProps = {
  draw: DrawOverviewPageDrawFragment;
  lotPartitionDraws: DrawOverviewPageLotPartitionDrawFragment[];
};

export type SaveDrawDetails = SaveCreditFacilityDrawInput;

export function DrawLotPartitionDrawsTable(props: DrawLotPartitionDrawsTableProps) {
  const { draw, lotPartitionDraws } = props;

  const formConfig: ObjectConfig<FormInput> = useMemo(
    () => ({
      id: { type: "value" },
      lotPartitionDraws: {
        type: "list",
        config: lotPartitionDrawConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      input: draw,
      map: mapToForm,
    },
    autoSave: saveLotPartitionDraws,
  });
  const [saveLotPartitionDraw] = useSaveLotPartitionDrawMutation();

  async function saveLotPartitionDraws(formState: ObjectState<FormInput>) {
    const changedLotPartitionDraws =
      formState.changedValue.lotPartitionDraws?.filter((lpd) => lpd.amountInCents !== undefined) || [];
    await Promise.all(
      changedLotPartitionDraws.map(async (lpd) =>
        saveLotPartitionDraw({
          variables: { input: { ...lpd } },
        }),
      ),
    );
  }

  const columns = createColumns(lotPartitionDraws, formState);
  const rows = mapToRows(lotPartitionDraws);

  return (
    <GridTable
      id="drawLotPartitionDrawsTable"
      columns={columns}
      rows={rows}
      rowStyles={rowStyles}
      sorting={{ on: "client" }}
      stickyHeader
      style={{ rowHeight: "flexible" }}
    />
  );
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

type Row = SimpleHeaderAndData<DrawOverviewPageLotPartitionDrawFragment>;

function createColumns(
  lotPartitionDraws: DrawOverviewPageLotPartitionDrawFragment[],
  formState: ObjectState<FormInput>,
): GridColumn<Row>[] {
  const lotPartitionIdColumn = column<Row>({
    header: "Lot Partition",
    data: (row) => row.lotPartition.id,
    w: "240px",
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: "Address",
    data: (row) => row.lotPartition.lot.address?.street1,
    w: "240px",
    sticky: "left",
  });

  const blueprintProjectColumn = column<Row>({
    header: "Blueprint Project",
    data: (row) => row.lotPartition.blueprintProjectId,
    w: "240px",
    sticky: "left",
  });

  const amountColumn = numericColumn<Row>({
    header: () => "Draw Amount",
    data: (row) => {
      const formStateField = formState.lotPartitionDraws.rows.find((lpd) => lpd.id.value === row.id);
      return {
        value: () => row.amountInCents,
        content: () => <BoundNumberField type="cents" field={formStateField!.amountInCents} />,
      };
    },
    w: "140px",
  });

  return [lotPartitionIdColumn, blueprintProjectColumn, addressColumn, amountColumn];
}

function mapToRows(lotPartitionDraws: DrawOverviewPageLotPartitionDrawFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lotPartitionDraws);
}

type FormInput = SaveCreditFacilityDrawInput & {
  lotPartitionDraws: Maybe<SaveLotPartitionDrawInput[]>;
};

function mapToForm(creditFacilityDraw: DrawOverviewPageDrawFragment): FormInput {
  return {
    id: creditFacilityDraw.id,
    lotPartitionDraws: mapLotPartitionDrawsToForm(creditFacilityDraw.lotPartitionDraws),
  };
}

export function mapLotPartitionDrawsToForm(lotPartitionDraws: Maybe<DrawOverviewPageLotPartitionDrawFragment[]>) {
  return lotPartitionDraws?.map((lpd) => {
    return {
      ...lpd,
    };
  });
}

export const lotPartitionDrawConfig: ObjectConfig<SaveLotPartitionDrawInput> = {
  id: { type: "value" },
  amountInCents: { type: "value" },
};
