import { Css, ScrollableContent } from "@homebound/beam";
import { useParams } from "react-router";
import { useLotOverviewLotQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { queryResult } from "src/utils/queryResult";
import { LotOverviewTabs } from "./LotOverviewTabs";

export function LotOverviewPage() {
  const { lotId } = useParams<{ lotId: string }>();
  const query = useLotOverviewLotQuery({ variables: { lotId } });

  return queryResult(query, {
    data: (data) => {
      const { lots } = data;
      const lot = lots[0];
      const title = `${lot.address?.street1}`;
      return (
        <>
          <PageHeader title={title} />
          <ScrollableContent>
            <div css={Css.df.py2.aifs.jcsb.$} data-testid="projectOverviewPage">
              <div css={Css.fg1.$}>
                <LotOverviewTabs lot={lot} />
              </div>
            </div>
          </ScrollableContent>
        </>
      );
    },
  });
}
