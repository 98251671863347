import {
  Button,
  column,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
} from "@homebound/beam";
import { Price } from "src/components";
import { FundOverviewPageDrawRequestFragment } from "src/generated/graphql-types";
import { createDrawRequestDetailsUrl } from "src/RouteUrls";
import { formatWithYear } from "src/utils/dates";
import { CreateDrawRequestModal } from "./CreateDrawRequestModal";

export type FundDrawRequestsTableProps = {
  drawRequests: FundOverviewPageDrawRequestFragment[];
  fundId: string;
};

export function FundDrawRequestsTable(props: FundDrawRequestsTableProps) {
  const { drawRequests, fundId } = props;

  const { openModal } = useModal();

  return (
    <>
      <GridTable
        id="drawsTable"
        rowStyles={rowStyles}
        columns={createDrawRequestColumns()}
        rows={createDrawRequestRows(drawRequests)}
        fallbackMessage="Draw Requests for this Fund will show here."
      />
      <Button
        label="Create Draw Request"
        variant="secondary"
        onClick={() =>
          openModal({
            content: <CreateDrawRequestModal fundId={fundId} />,
          })
        }
      />
    </>
  );
}

type Row = SimpleHeaderAndData<FundOverviewPageDrawRequestFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {
    onClick: ({ data }) => {
      window.open(`${createDrawRequestDetailsUrl(data.id)}`, "_blank", "noopener,noreferrer");
    },
  },
};

function createDrawRequestRows(draws: FundOverviewPageDrawRequestFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(draws);
}

function createDrawRequestColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Credit Facility Draw Request ID",
    data: ({ id }) => id,
  });

  const preparationDateColumn = dateColumn<Row>({
    header: () => "Draw Date",
    data: ({ preparationDate }) => formatWithYear(preparationDate),
  });

  const amountColumn = column<Row>({
    header: () => "Amount Drawn",
    data: ({ amountInCents }) => getPriceComponent(amountInCents),
  });

  const drawIdColumn = column<Row>({
    header: () => "Draw ID",
    data: ({ creditFacilityDraw }) => creditFacilityDraw?.id || "None",
  });

  return [idColumn, preparationDateColumn, amountColumn, drawIdColumn];
}

function getPriceComponent(value: number | undefined) {
  return <Price valueInCents={value} dropZero />;
}
