import {
  BoundNumberField,
  Button,
  column,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { ReactNode, useMemo } from "react";
import { Percentage, Price } from "src/components";
import {
  DrawRequestOverviewPageDrawRequestFragment,
  DrawRequestOverviewPageLotPartitionDrawRequestFragment,
  Maybe,
  SaveCreditFacilityDrawRequestInput,
  SaveLotPartitionDrawRequestInput,
  useRefreshGoldmanDrawModelBlueprintDataMutation,
  useSaveLotPartitionDrawRequestMutation,
} from "src/generated/graphql-types";

type DrawRequestLotPartitionDrawRequestsTableProps = {
  drawRequest: DrawRequestOverviewPageDrawRequestFragment;
  lotPartitionDrawRequests: DrawRequestOverviewPageLotPartitionDrawRequestFragment[];
};

export type SaveDrawRequestDetails = SaveCreditFacilityDrawRequestInput;

export function DrawRequestLotPartitionDrawRequestsTable(props: DrawRequestLotPartitionDrawRequestsTableProps) {
  const { drawRequest, lotPartitionDrawRequests } = props;
  const isGoldmanDrawModel = lotPartitionDrawRequests[0].goldmanDrawModel?.id !== undefined; // Either they will all be there or none will, so just check the first one

  const formConfig: ObjectConfig<FormInput> = useMemo(
    () => ({
      id: { type: "value" },
      lotPartitionDrawRequests: {
        type: "list",
        config: lotPartitionDrawRequestConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      input: drawRequest,
      map: mapToForm,
    },
    autoSave: saveLotPartitionDrawRequests,
  });
  const [saveLotPartitionDrawRequest] = useSaveLotPartitionDrawRequestMutation();
  const [refreshGSDrawBPData] = useRefreshGoldmanDrawModelBlueprintDataMutation();

  async function saveLotPartitionDrawRequests(formState: ObjectState<FormInput>) {
    const changedLotPartitionDrawRequests =
      formState.changedValue.lotPartitionDrawRequests?.filter((lpd) => lpd.amountInCents !== undefined) || [];
    await Promise.all(
      changedLotPartitionDrawRequests.map(async (lpd) =>
        saveLotPartitionDrawRequest({
          variables: { input: { ...lpd } },
        }),
      ),
    );
  }

  const columns = createColumns(lotPartitionDrawRequests, formState, !drawRequest.canEdit.allowed, isGoldmanDrawModel);
  const rows = mapToRows(lotPartitionDrawRequests);

  const goldmanDrawModelIds = lotPartitionDrawRequests.map((lpd) => lpd.goldmanDrawModel?.id).compact();

  return (
    <>
      <GridTable
        id="drawRequestLotPartitionDrawRequestsTable"
        columns={columns}
        rows={rows}
        rowStyles={rowStyles}
        sorting={{ on: "client" }}
        stickyHeader
        style={{ rowHeight: "flexible" }}
      />
      <Button
        label="Refresh Blueprint Data"
        variant="secondary"
        onClick={async () => {
          for (const goldmanDrawModelId of goldmanDrawModelIds) {
            // This is almost certainly slower than doing it in bulk, but the server side is doing them one by one anyway, so this is helpfing debug for now at least
            await refreshGSDrawBPData({ variables: { input: { goldmanDrawModelId: [goldmanDrawModelId] } } });
            console.log("Refreshed Blueprint Data for Goldman Draw Model with ID: ", goldmanDrawModelId);
          }
        }}
        disabled={!isGoldmanDrawModel}
      />
      <Button
        label="Refresh Draw Request Amount"
        variant="secondary"
        onClick={async () => {
          formState.lotPartitionDrawRequests.rows.forEach((row) => {
            const lpdr = lotPartitionDrawRequests.find((basicRow) => basicRow.id === row.id.value);
            if (lpdr) {
              row.amountInCents.set(
                lpdr.modeledCollectiveAmountToBorrowInCents - lpdr.lotPartition.borrowedAmountInCents,
              );
            }
          });
        }}
        disabled={!isGoldmanDrawModel}
      />
    </>
  );
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

type Row = SimpleHeaderAndData<DrawRequestOverviewPageLotPartitionDrawRequestFragment>;

function createColumns(
  lotPartitionDrawRequests: DrawRequestOverviewPageLotPartitionDrawRequestFragment[],
  formState: ObjectState<FormInput>,
  isReadOnly: boolean,
  isGoldmanDrawModel: boolean,
): GridColumn<Row>[] {
  const lotPartitionIdColumn = column<Row>({
    header: "Lot Partition",
    data: (row) => row.lotPartition.id,
    w: "240px",
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: "Address",
    data: (row) => row.lotPartition.lot.address?.street1,
    w: "240px",
    sticky: "left",
  });

  const blueprintProjectColumn = column<Row>({
    header: "Blueprint Project",
    data: (row) => row.lotPartition.blueprintProjectId,
    w: "240px",
    sticky: "left",
  });

  const drawAmountColumn = numericColumn<Row>({
    header: () => "Draw Request Amount",
    data: (row) => {
      const formStateField = formState.lotPartitionDrawRequests.rows.find((lpd) => lpd.id.value === row.id);
      return {
        value: () => row.amountInCents,
        content: () => <BoundNumberField type="cents" field={formStateField!.amountInCents} readOnly={isReadOnly} />,
      };
    },
    w: "140px",
  });

  const modelAmountColumn = numericColumn<Row>({
    header: () => "Model Amount (Individual)",
    data: (row) => amountGridCellContent(row.modeledIndividualAmountToBorrowInCents),
    w: "140px",
  });

  const modelCollectiveAmountColumn = numericColumn<Row>({
    header: () => "Model Amount (With Collective Adjustments)",
    data: (row) => amountGridCellContent(row.modeledCollectiveAmountToBorrowInCents),
    w: "140px",
  });

  const amountBorrowedColumn = numericColumn<Row>({
    header: () => "Amount Borrowed",
    data: (row) => amountGridCellContent(row.lotPartition.borrowedAmountInCents),
    w: "140px",
  });

  const columns = [
    lotPartitionIdColumn,
    blueprintProjectColumn,
    addressColumn,
    drawAmountColumn,
    modelAmountColumn,
    modelCollectiveAmountColumn,
    amountBorrowedColumn,
  ];

  if (isGoldmanDrawModel) {
    const isEligibleColumn = column<Row>({
      header: "Eligible",
      data: (row) => (row.goldmanDrawModel?.isEligibleForGoldmanDraw ? "Yes" : "No"),
      w: "140px",
    });
    columns.push(isEligibleColumn);

    const isTargetColumn = column<Row>({
      header: "isTargetBuy",
      data: (row) => (row.goldmanDrawModel?.isTargetBuy ? "Yes" : "No"),
      w: "140px",
    });
    columns.push(isTargetColumn);

    const isExpandedColumn = column<Row>({
      header: "isExpandedBuy",
      data: (row) => (row.goldmanDrawModel?.isExpandedBuy ? "Yes" : "No"),
      w: "140px",
    });
    columns.push(isExpandedColumn);

    const isFinishedPropertyColumn = column<Row>({
      header: "isFinishedProperty",
      data: (row) => (row.goldmanDrawModel?.isFinishedProperty ? "Yes" : "No"),
      w: "140px",
    });
    columns.push(isFinishedPropertyColumn);

    const currentAppraisalAmountColumn = column<Row>({
      header: "Current Appraisal Amount",
      data: (row) => amountGridCellContent(row.goldmanDrawModel?.currentAppraisalValueInCents || 0),
      w: "140px",
    });
    columns.push(currentAppraisalAmountColumn);

    const advanceRateColumn = column<Row>({
      header: "Advance Rate",
      data: (row) => amountGridCellContent((row.goldmanDrawModel?.advanceRateBasisPoints || 0) / 100, true),
      w: "140px",
    });
    columns.push(advanceRateColumn);

    const adjustedCollateralValueInCents = column<Row>({
      header: "Adjusted Collateral Value",
      data: (row) => amountGridCellContent(row.goldmanDrawModel?.adjustedCollateralValueInCents || 0),
      w: "140px",
    });
    columns.push(adjustedCollateralValueInCents);

    const adjustedAdvanceRateColumn = column<Row>({
      header: "Adjusted Advance Rate",
      data: (row) => amountGridCellContent((row.goldmanDrawModel?.adjustedAdvanceRateBasisPoints || 0) / 100, true),
      w: "140px",
    });
    columns.push(adjustedAdvanceRateColumn);

    const realizedConstructionExpensesColumn = column<Row>({
      header: "Realized Construction Expenses (AU)",
      data: (row) => amountGridCellContent(row.goldmanDrawModel?.realizedConstructionExpensesInCents || 0),
      w: "140px",
    });
    columns.push(realizedConstructionExpensesColumn);
  }

  return columns;
}

function amountGridCellContent(amount: number, usePercent = false): GridCellContent {
  return {
    alignment: "left",
    content: usePercent ? percentCellContent(amount) : priceCellContent(amount),
    value: amount,
  };
}

function priceCellContent(amount: number): ReactNode {
  return <Price valueInCents={amount} />;
}

function percentCellContent(percent: number): ReactNode {
  return <Percentage percent={percent} />;
}

function mapToRows(
  lotPartitionDrawRequests: DrawRequestOverviewPageLotPartitionDrawRequestFragment[],
): GridDataRow<Row>[] {
  return simpleDataRows(lotPartitionDrawRequests);
}

type FormInput = SaveCreditFacilityDrawRequestInput & {
  lotPartitionDrawRequests: Maybe<SaveLotPartitionDrawRequestInput[]>;
};

function mapToForm(creditFacilityDrawRequest: DrawRequestOverviewPageDrawRequestFragment): FormInput {
  return {
    id: creditFacilityDrawRequest.id,
    lotPartitionDrawRequests: mapLotPartitionDrawRequestsToForm(creditFacilityDrawRequest.lotPartitionDrawRequests),
  };
}

export function mapLotPartitionDrawRequestsToForm(
  lotPartitionDrawRequests: Maybe<DrawRequestOverviewPageLotPartitionDrawRequestFragment[]>,
) {
  return lotPartitionDrawRequests?.map((lpd) => {
    return {
      ...lpd,
    };
  });
}

export const lotPartitionDrawRequestConfig: ObjectConfig<SaveLotPartitionDrawRequestInput> = {
  id: { type: "value" },
  amountInCents: { type: "value" },
};
