// Monkey patch window.fetch to debug terribly opaque "Fetch failed" Errors
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  const [resource, config] = args;

  // Normalize the `resource` param into a simple url
  const url =
    typeof resource === "object" && "url" in resource
      ? resource.url
      : typeof resource === "string"
        ? resource
        : JSON.stringify(resource);

  try {
    const response = await originalFetch(resource, config);
    if (!response.ok) {
      // This is just repeating what apolloHooks.ts's toastOnError does, but keeping for now while debugging.
      console.error("Fetch failed calling " + url + " " + config?.body);
    }
    return response;
  } catch (e) {
    // Do console.error with the debugging information
    console.error("Fetch blew up calling " + url + " " + config?.body);
    // And make sure to throw a TypeError so that `retryIf` can tell we should retry.
    throw new TypeError("Fetch failed calling " + url);
  }
};

export {};
