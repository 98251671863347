import { ApolloError } from "@apollo/client";
import { Css, useTestIds } from "@homebound/beam";
import { getErrorMessages } from "src/utils/error";

export function ErrorMessages({ error }: { error: ApolloError | Error }) {
  const errorMessages = getErrorMessages(error);
  const tid = useTestIds({}, "errorMessages");

  return (
    <>
      {errorMessages && (
        <div css={Css.tal.wPx(460).mr("auto").ml("auto").$}>
          <p css={Css.mb1.baseMd.$} {...tid.label}>
            Errors
          </p>
          <pre css={Css.wsn.bgGray100.p2.$}>
            <ul css={Css.m0.pl2.$}>
              {errorMessages.map((message, i) => (
                <li key={i} {...tid}>
                  {message}
                </li>
              ))}
            </ul>
          </pre>
        </div>
      )}
    </>
  );
}
