import { TypePolicies } from "@apollo/client";
import { enumTypePolicies, scalarTypePolicies } from "src/generated/graphql-types";

export const typePolicies: TypePolicies = {
  ...enumTypePolicies,
  ...scalarTypePolicies,
  // If separate queries ask for different subsets of value objects, just merge them
  CommentStreams: { merge: true },
  Query: {
    fields: {
      toDos: {
        keyArgs: ["filter"],
        merge(existing, incoming, { args }) {
          const merged = existing ? existing.slice(0) : [];
          if (args) {
            const { first = 0 } = args;
            for (let i = 0; i < incoming.length; ++i) {
              merged[first + i] = incoming[i];
            }
          } else {
            merged.push.apply(merged, incoming);
          }
          return merged;
        },
      },
    },
  },
  // HSPO's are transient entities, so we want to disable normalization (i.e. skip caching)
  HomeownerSelectionProductOption: { keyFields: false },
  // Custom merge function added so that cache will update properly when task dependencies are deleted
  // `merge: false` means the incoming data will be preferred over existing data
  ScheduleTask: {
    fields: {
      kind: {
        read() {
          // TODO: Change to ScheduleTask
          return "task";
        },
      },
    },
  },
};
